var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { createGlobalStyle } from 'styled-components';
import ThemeStyles from './ThemeStyles';
import ResetStyles from './ResetStyles';
import FontStyles from './FontStyles';
import media from '@utils/media';
var GlobalStyles = createGlobalStyle(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\n  /* Theme styles and variables */\n  ", "\n\n  /* Style Resets */\n  ", "\n\n  /* Fontface styles */\n  ", "\n  \n\n  html {\n    font-family: PoppinsRegular;\n    font-size: 62.5%;\n  }\n  \n  html,\n  body,\n  #root {\n    height: 100vh;\n    overflow: hidden;\n  }\n\n  ", "\n    *{\n    &::-webkit-scrollbar {\n    width: 0.4rem;\n    border-radius: 0.45rem;\n  }\n  &::-webkit-scrollbar-thumb {\n    -webkit-border-radius: 0.1rem;\n    border-radius: 0.45rem;\n    -webkit-box-shadow: inset 0 0 0.6rem rgba(0,0,0,0.15); \n  }\n  }\n"], ["\n\n  /* Theme styles and variables */\n  ", "\n\n  /* Style Resets */\n  ", "\n\n  /* Fontface styles */\n  ", "\n  \n\n  html {\n    font-family: PoppinsRegular;\n    font-size: 62.5%;\n  }\n  \n  html,\n  body,\n  #root {\n    height: 100vh;\n    overflow: hidden;\n  }\n\n  ", "\n    *{\n    &::-webkit-scrollbar {\n    width: 0.4rem;\n    border-radius: 0.45rem;\n  }\n  &::-webkit-scrollbar-thumb {\n    -webkit-border-radius: 0.1rem;\n    border-radius: 0.45rem;\n    -webkit-box-shadow: inset 0 0 0.6rem rgba(0,0,0,0.15); \n  }\n  }\n"])), ThemeStyles, ResetStyles, FontStyles, media.lessThan('xl')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    .icon-container {\n      margin-right: 0;\n    }\n  "], ["\n    .icon-container {\n      margin-right: 0;\n    }\n  "]))));
export default GlobalStyles;
var templateObject_1, templateObject_2;
