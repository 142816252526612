var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import monthlyScheduleApi from '@store/api/monthlySchedule.api';
import message from './message';
var initialState = {
    monthlySchedule: {
        data: {},
        loading: true,
        error: ''
    },
    teamMembers: {
        data: { members: [] },
        loading: true
    },
    scheduledHistory: {
        data: [],
        loading: true
    },
    editShift: {
        loading: true,
        error: ''
    }
};
var getError = function (code) {
    switch (code) {
        case 200:
            return '';
        default:
            return message.shiftPlanEditFailure;
    }
};
export var fetchMonthlySchedule = createAsyncThunk('monthlySchedule/fetchMonthlySchedule', function (month, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyScheduleApi.endpoints.getMonthlySchedule.initiate(month))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var fetchTeamMembers = createAsyncThunk('user/fetchTeamMembers', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyScheduleApi.endpoints.getProjectMembers.initiate({
                    forceRefetch: false
                }))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var fetchScheduledHistory = createAsyncThunk('monthlySchedule/fetchscheduledHistory', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyScheduleApi.endpoints.getScheduledHistory.initiate())];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var swapShifts = createAsyncThunk('monthly/swap-shifts', function (_a, thunkApi) {
    var assigned = _a.assigned, replaced = _a.replaced, period = _a.period;
    return __awaiter(void 0, void 0, void 0, function () {
        var payload;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    payload = {
                        assigned: {
                            current_shifts_id: assigned.current_shifts_id,
                            employees: assigned.employees
                        }
                    };
                    if (replaced) {
                        payload.replaced = {
                            current_shifts_id: replaced.current_shifts_id,
                            employees: replaced.employees
                        };
                    }
                    return [4 /*yield*/, thunkApi.dispatch(monthlyScheduleApi.endpoints.swapShifts.initiate(__assign(__assign({}, payload), { period: period })))];
                case 1:
                    void (_b.sent());
                    return [2 /*return*/];
            }
        });
    });
});
export var monthlyScheduleSlice = createSlice({
    name: 'monthly-schedule',
    initialState: initialState,
    reducers: {
        setMonthlySchedule: function (state, action) {
            state.monthlySchedule.data = action.payload.data;
        }
    },
    extraReducers: function (builder) {
        builder
            .addMatcher(monthlyScheduleApi.endpoints.getMonthlySchedule.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.monthlySchedule.loading = true;
        })
            .addMatcher(monthlyScheduleApi.endpoints.getMonthlySchedule.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.monthlySchedule.loading = false;
            state.monthlySchedule.error = payload === null || payload === void 0 ? void 0 : payload.data.message;
            state.monthlySchedule.error = payload === null || payload === void 0 ? void 0 : payload.data.message;
        })
            .addMatcher(monthlyScheduleApi.endpoints.getMonthlySchedule.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.monthlySchedule.loading = false;
            state.monthlySchedule.data = payload.result;
        });
        builder
            .addMatcher(monthlyScheduleApi.endpoints.getScheduledHistory.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.scheduledHistory.loading = true;
        })
            .addMatcher(monthlyScheduleApi.endpoints.getScheduledHistory.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.scheduledHistory.loading = false;
        })
            .addMatcher(monthlyScheduleApi.endpoints.getScheduledHistory.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.scheduledHistory.loading = false;
            state.scheduledHistory.data = payload.result;
        });
        builder
            .addMatcher(monthlyScheduleApi.endpoints.getProjectMembers.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.teamMembers.loading = true;
        })
            .addMatcher(monthlyScheduleApi.endpoints.getProjectMembers.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.teamMembers.loading = false;
        })
            .addMatcher(monthlyScheduleApi.endpoints.getProjectMembers.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.teamMembers.loading = false;
            state.teamMembers.data = payload.result;
        });
        builder
            .addMatcher(monthlyScheduleApi.endpoints.swapShifts.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.editShift.loading = true;
        })
            .addMatcher(monthlyScheduleApi.endpoints.swapShifts.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.editShift.loading = false;
            state.editShift.error = getError(payload === null || payload === void 0 ? void 0 : payload.data.status_code);
        })
            .addMatcher(monthlyScheduleApi.endpoints.swapShifts.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.editShift.loading = false;
            state.editShift.error = getError(payload.status_code);
        });
    }
});
export var setMonthlySchedule = monthlyScheduleSlice.actions.setMonthlySchedule;
export default monthlyScheduleSlice.reducer;
