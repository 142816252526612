var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import media from '@utils/media';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  position: fixed;\n  top: 0;\n  left:0;\n  z-index: 99;\n  background-color: #0000004d;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"], ["\n  height: 100%;\n  width: 100%;\n  position: fixed;\n  top: 0;\n  left:0;\n  z-index: 99;\n  background-color: #0000004d;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n"])));
var Wrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  &.popup {\n    width: 28.2rem;\n    background-color: #fff;\n    border-radius: 0.8rem;\n  }\n  &.buttonWrapper {\n    height: 4.8rem;\n    border-radius: 0 0 0.8rem 0.8rem;\n    background-color: #f8f8f8;\n    display: flex;\n    justify-content: right;\n    align-items: center;\n    user-select: none;\n  }\n  ", ";\n  ", "\n"], ["\n  &.popup {\n    width: 28.2rem;\n    background-color: #fff;\n    border-radius: 0.8rem;\n  }\n  &.buttonWrapper {\n    height: 4.8rem;\n    border-radius: 0 0 0.8rem 0.8rem;\n    background-color: #f8f8f8;\n    display: flex;\n    justify-content: right;\n    align-items: center;\n    user-select: none;\n  }\n  ", ";\n  ", "\n"])), media.lessThan('sm')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &.popup {\n    width: 24.35rem;\n  }\n  &.buttonWrapper {\n    height: 4.4rem;\n  }\n  "], ["\n  &.popup {\n    width: 24.35rem;\n  }\n  &.buttonWrapper {\n    height: 4.4rem;\n  }\n  "]))), function (_a) {
    var customStyles = _a.customStyles;
    return "".concat(customStyles);
});
var Title = styled.p(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-family: PoppinsSemiBold;\n  font-size: 1.8rem;\n  font-weight: 600;\n  margin: 1.5rem 0 2.1rem 2.4rem;\n  ", "\n"], ["\n  font-family: PoppinsSemiBold;\n  font-size: 1.8rem;\n  font-weight: 600;\n  margin: 1.5rem 0 2.1rem 2.4rem;\n  ", "\n"])), media.lessThan('sm')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    font-size: 1.5rem;\n    margin-bottom: 1.7rem;\n  "], ["\n    font-size: 1.5rem;\n    margin-bottom: 1.7rem;\n  "]))));
var Button = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  outline: none;\n  border: none;\n  background: none;\n  font-family: PoppinsMedium;\n  font-size: 1.4rem;\n  font-weight: 500;\n  cursor: pointer;\n  padding: 13px 21px 14px 0;\n  &.cancel {\n    color: #6e6c7d;\n  }\n  &.confirm {\n    color: var(--primary-color);\n  }\n  ", "\n"], ["\n  outline: none;\n  border: none;\n  background: none;\n  font-family: PoppinsMedium;\n  font-size: 1.4rem;\n  font-weight: 500;\n  cursor: pointer;\n  padding: 13px 21px 14px 0;\n  &.cancel {\n    color: #6e6c7d;\n  }\n  &.confirm {\n    color: var(--primary-color);\n  }\n  ", "\n"])), media.lessThan('sm')(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    font-size: 1.25rem;\n  "], ["\n    font-size: 1.25rem;\n  "]))));
export default {
    Container: Container,
    Wrapper: Wrapper,
    Title: Title,
    Button: Button
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
