import { api } from './common.api';
import { USER_PATHS } from '@constants/api.constants';
import { ApiMethods } from '@enums/api.enum';
export var userApi = api.injectEndpoints({
    endpoints: function (build) { return ({
        getUser: build.query({
            query: function () { return USER_PATHS.getUser; },
            forceRefetch: function () { return true; }
        }),
        patchPhoneNumber: build.mutation({
            query: function (body) { return ({
                url: USER_PATHS.editPhoneNumber,
                method: ApiMethods.PATCH,
                body: body
            }); }
        })
    }); }
});
export var useGetUserQuery = userApi.useGetUserQuery;
export default userApi;
