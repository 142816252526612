var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import AppLogo from '@assets/images/logo_black.webp';
import { NavLink } from 'react-router-dom';
import media from '@utils/media';
var Container = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 15%;\n  min-width: 15%;\n  display: flex;\n  flex-direction: column;\n  padding: 29px 0 0 32px;\n  overflow: hidden;\n  transition: 0.3s;\n  ", "\n  ", "\n  ", "\n"], ["\n  width: 15%;\n  min-width: 15%;\n  display: flex;\n  flex-direction: column;\n  padding: 29px 0 0 32px;\n  overflow: hidden;\n  transition: 0.3s;\n  ", "\n  ", "\n  ", "\n"])), media.lessThan('xl')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    padding: 3rem 0;\n    align-items: center;\n  "], ["\n    padding: 3rem 0;\n    align-items: center;\n  "]))), media.between('lg', 'xl')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    min-width: 10%;\n  "], ["\n    min-width: 10%;\n  "]))), media.lessThan('sm')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    min-width: 18%;\n  "], ["\n    min-width: 18%;\n  "]))));
var Logo = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 77px;\n  height: 33px;\n  background: url(", ") no-repeat;\n  background-size: contain;\n  ", "\n"], ["\n  width: 77px;\n  height: 33px;\n  background: url(", ") no-repeat;\n  background-size: contain;\n  ", "\n"])), AppLogo, media.lessThan('lg')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    width: 55px;\n    height: 25px;\n  "], ["\n    width: 55px;\n    height: 25px;\n  "]))));
var LinksWrap = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 7rem;\n  display: flex;\n  flex-flow: column;\n  width: 100%;\n"], ["\n  margin-top: 7rem;\n  display: flex;\n  flex-flow: column;\n  width: 100%;\n"])));
var LinkIconStyles = css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-right: 17px;\n"], ["\n  margin-right: 17px;\n"])));
var Link = styled(NavLink)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 16px;\n  font-weight: 500;\n  color: #6e6c7d;\n  padding: 10px 0;\n  text-decoration: none;\n  display: flex;\n  align-items: center;\n  margin-bottom: 3rem;\n  position: relative;\n  ::after {\n    content: '';\n    display: block;\n    position: absolute;\n    height: 100%;\n    width: 4px; \n    border-radius: 100px 0 0 100px;\n    background-color: var(--primary-color);\n    top: 0;\n    right: -5px;  \n    transition: 0.2s;\n  }\n  &.active {\n    font-family: PoppinsSemiBold;\n    color: #4e5cff;\n    ", " {\n      g[stroke] {\n        stroke: #4e5cff;\n      }\n      path[fill] {\n        fill: #4e5cff;\n      }\n      path[stroke] {\n        stroke: #4e5cff;\n      }\n    }\n    ::after {\n      right: 0;\n    }\n    margin-right: 0;\n  }\n  ", "\n  "], ["\n  font-size: 16px;\n  font-weight: 500;\n  color: #6e6c7d;\n  padding: 10px 0;\n  text-decoration: none;\n  display: flex;\n  align-items: center;\n  margin-bottom: 3rem;\n  position: relative;\n  ::after {\n    content: '';\n    display: block;\n    position: absolute;\n    height: 100%;\n    width: 4px; \n    border-radius: 100px 0 0 100px;\n    background-color: var(--primary-color);\n    top: 0;\n    right: -5px;  \n    transition: 0.2s;\n  }\n  &.active {\n    font-family: PoppinsSemiBold;\n    color: #4e5cff;\n    ", " {\n      g[stroke] {\n        stroke: #4e5cff;\n      }\n      path[fill] {\n        fill: #4e5cff;\n      }\n      path[stroke] {\n        stroke: #4e5cff;\n      }\n    }\n    ::after {\n      right: 0;\n    }\n    margin-right: 0;\n  }\n  ", "\n  "])), LinkIconStyles, media.lessThan('xl')(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n    justify-content: center;\n    span:not(.icon-container) {\n      display: none;\n    }\n  "], ["\n    justify-content: center;\n    span:not(.icon-container) {\n      display: none;\n    }\n  "]))));
var Version = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  font: 14px PoppinsRegular;\n  color: #6E6C7D;\n  position: absolute;\n  bottom: 2.4rem;\n  "], ["\n  font: 14px PoppinsRegular;\n  color: #6E6C7D;\n  position: absolute;\n  bottom: 2.4rem;\n  "])));
export default {
    Container: Container,
    Logo: Logo,
    LinksWrap: LinksWrap,
    Link: Link,
    LinkIconStyles: LinkIconStyles,
    Version: Version
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
