var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import shiftGenerationsApi from '@store/api/shiftGeneration.api';
import messages from './messages';
var initialState = {
    shiftGenStatus: {
        data: {},
        loading: true
    },
    initiateSurvey: {
        data: {},
        loading: true,
        error: {}
    },
    leaveRequests: {
        data: {},
        loading: true,
        error: {}
    },
    unavailableWeekendsRequests: {
        data: {},
        loading: true,
        error: {}
    },
    saveLeaveApprovals: {
        data: {},
        loading: true,
        error: {}
    },
    generateShiftPlan: {
        data: {},
        loading: true,
        error: {}
    },
    shiftSummary: {
        data: [],
        loading: true,
        error: {}
    },
    reports: {
        data: [],
        loading: false,
        error: ''
    },
    publishShift: {
        error: '',
        loading: false
    }
};
export var fetchShiftGenStatus = createAsyncThunk('shiftGeneration/fetchShiftGenStatus', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(shiftGenerationsApi.endpoints.getShiftGenStatus.initiate(_))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var initiateSurvey = createAsyncThunk('shiftGeneration/initiateSurvey', function (_a, thunkApi) {
    var startDate = _a.startDate, endDate = _a.endDate, monthlyPreferenceDeadline = _a.monthlyPreferenceDeadline;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, thunkApi.dispatch(shiftGenerationsApi.endpoints.initiateSurvey.initiate({
                        start_date: startDate,
                        end_date: endDate,
                        monthly_preference_deadline: monthlyPreferenceDeadline
                    }))];
                case 1:
                    void (_b.sent());
                    return [2 /*return*/];
            }
        });
    });
});
export var fetchLeaveRequests = createAsyncThunk('shiftGeneration/fetchLeaveRequests', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(shiftGenerationsApi.endpoints.getLeaveRequests.initiate(_))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var fetchUnavailableWeekendRequests = createAsyncThunk('shiftGeneration/fetchUnavailableWeekendRequests', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(shiftGenerationsApi.endpoints.getUnavailableWeekendRequests.initiate(_))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var saveLeaveRequestApprovals = createAsyncThunk('shiftGeneration/initiateSurvey', function (monthlyPreferences, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(shiftGenerationsApi.endpoints.saveLeaveRequestApprovals.initiate({
                    monthly_preferences: monthlyPreferences
                }))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var getShiftSummary = createAsyncThunk('shiftGeneration/getShiftSummary', function (period, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(shiftGenerationsApi.endpoints.getShiftSummary.initiate(period))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var generateShiftPlan = createAsyncThunk('shiftGeneration/generateShiftPlan', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(shiftGenerationsApi.endpoints.generateShift.initiate(_))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var getShiftReport = createAsyncThunk('shiftGeneration/shiftReport', function (period, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(shiftGenerationsApi.endpoints.getShiftReport.initiate(period))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var publishSurvey = createAsyncThunk('shiftGeneration/publishSurvey', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(shiftGenerationsApi.endpoints.publishSurvey.initiate(_))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
var getError = function (code) {
    switch (code) {
        case 404:
            return messages.submitApiFailure;
        case 409:
            return messages.submitApiFailure;
        case 404001:
            return messages.invalidRequest;
        case 500001:
            return messages.serverError;
        case 400000:
            return messages.invalidStatus;
        case 400003:
            return messages.validityError;
        case 200:
            return '';
        case 409000:
            return messages.preferenceUpdateFailure;
        default:
            return messages.submitApiFailure;
    }
};
export var shiftGenerationsSlice = createSlice({
    name: 'shift-generation',
    initialState: initialState,
    reducers: {
        setShiftGeneration: function (state, action) {
            state.shiftGenStatus.data = action.payload.data;
        }
    },
    extraReducers: function (builder) {
        builder
            .addMatcher(shiftGenerationsApi.endpoints.getShiftGenStatus.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.shiftGenStatus.loading = true;
        })
            .addMatcher(shiftGenerationsApi.endpoints.getShiftGenStatus.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.shiftGenStatus.loading = false;
        })
            .addMatcher(shiftGenerationsApi.endpoints.getShiftGenStatus.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.shiftGenStatus.loading = false;
            state.shiftGenStatus.data = payload.result;
        });
        builder
            .addMatcher(shiftGenerationsApi.endpoints.initiateSurvey.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.initiateSurvey.loading = true;
        })
            .addMatcher(shiftGenerationsApi.endpoints.initiateSurvey.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.initiateSurvey.loading = false;
            state.initiateSurvey.error = __assign(__assign({}, payload === null || payload === void 0 ? void 0 : payload.data), { message: getError(payload === null || payload === void 0 ? void 0 : payload.originalStatus) });
        })
            .addMatcher(shiftGenerationsApi.endpoints.initiateSurvey.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.initiateSurvey.loading = false;
            state.initiateSurvey.data = payload.result;
        });
        builder
            .addMatcher(shiftGenerationsApi.endpoints.getLeaveRequests.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.leaveRequests.loading = true;
        })
            .addMatcher(shiftGenerationsApi.endpoints.getLeaveRequests.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.leaveRequests.loading = false;
        })
            .addMatcher(shiftGenerationsApi.endpoints.getLeaveRequests.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.leaveRequests.loading = false;
            state.leaveRequests.data = payload.result;
        });
        builder
            .addMatcher(shiftGenerationsApi.endpoints.getUnavailableWeekendRequests
            .matchPending, function (state, _a) {
            var payload = _a.payload;
            state.unavailableWeekendsRequests.loading = true;
        })
            .addMatcher(shiftGenerationsApi.endpoints.getUnavailableWeekendRequests
            .matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.unavailableWeekendsRequests.loading = false;
        })
            .addMatcher(shiftGenerationsApi.endpoints.getUnavailableWeekendRequests
            .matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.unavailableWeekendsRequests.loading = false;
            state.unavailableWeekendsRequests.data = payload.result;
        });
        builder
            .addMatcher(shiftGenerationsApi.endpoints.saveLeaveRequestApprovals.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.saveLeaveApprovals.loading = true;
        })
            .addMatcher(shiftGenerationsApi.endpoints.saveLeaveRequestApprovals.matchRejected, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.saveLeaveApprovals.loading = false;
            state.saveLeaveApprovals.error = __assign(__assign({}, payload === null || payload === void 0 ? void 0 : payload.data), { message: getError((_b = payload === null || payload === void 0 ? void 0 : payload.data) === null || _b === void 0 ? void 0 : _b.status_code) });
        })
            .addMatcher(shiftGenerationsApi.endpoints.saveLeaveRequestApprovals.matchFulfilled, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.saveLeaveApprovals.loading = false;
            state.saveLeaveApprovals.data = payload.result;
            state.saveLeaveApprovals.error = __assign(__assign({}, payload === null || payload === void 0 ? void 0 : payload.data), { message: getError((_b = payload === null || payload === void 0 ? void 0 : payload.data) === null || _b === void 0 ? void 0 : _b.status_code) });
        });
        builder
            .addMatcher(shiftGenerationsApi.endpoints.generateShift.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.generateShiftPlan.loading = true;
        })
            .addMatcher(shiftGenerationsApi.endpoints.generateShift.matchRejected, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.generateShiftPlan.loading = false;
            state.generateShiftPlan.error = __assign(__assign({}, payload === null || payload === void 0 ? void 0 : payload.data), { message: getError((_b = payload === null || payload === void 0 ? void 0 : payload.data) === null || _b === void 0 ? void 0 : _b.status_code) });
        })
            .addMatcher(shiftGenerationsApi.endpoints.generateShift.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.generateShiftPlan.loading = false;
            state.generateShiftPlan.data = payload.result;
            state.generateShiftPlan.error = __assign(__assign({}, payload === null || payload === void 0 ? void 0 : payload.data), { message: getError(payload === null || payload === void 0 ? void 0 : payload.status_code) });
        });
        builder
            .addMatcher(shiftGenerationsApi.endpoints.getShiftSummary.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.shiftSummary.loading = true;
        })
            .addMatcher(shiftGenerationsApi.endpoints.getShiftSummary.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.shiftSummary.loading = false;
        })
            .addMatcher(shiftGenerationsApi.endpoints.getShiftSummary.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.shiftSummary.loading = false;
            state.shiftSummary.data = payload.result;
        });
        builder
            .addMatcher(shiftGenerationsApi.endpoints.getShiftReport.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.reports.loading = true;
        })
            .addMatcher(shiftGenerationsApi.endpoints.getShiftReport.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.reports.loading = false;
            state.reports.error = getError(payload === null || payload === void 0 ? void 0 : payload.originalStatus);
            state.reports.data = [];
        })
            .addMatcher(shiftGenerationsApi.endpoints.getShiftReport.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.reports.loading = false;
            state.reports.data = payload.result;
        });
        builder
            .addMatcher(shiftGenerationsApi.endpoints.publishSurvey.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.publishShift.loading = true;
            state.publishShift.error = '';
        })
            .addMatcher(shiftGenerationsApi.endpoints.publishSurvey.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.publishShift.loading = false;
            state.publishShift.error = getError(payload === null || payload === void 0 ? void 0 : payload.originalStatus);
        })
            .addMatcher(shiftGenerationsApi.endpoints.publishSurvey.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.publishShift.loading = false;
            // state.publishShift.error = getError(payload?.originalStatus);
        });
    }
});
export var setShiftGeneration = shiftGenerationsSlice.actions.setShiftGeneration;
export default shiftGenerationsSlice.reducer;
