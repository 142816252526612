export default {
    basicDet: 'Basic project details',
    projectName: 'Project name',
    projectNamePlaceholder: 'Type project name here',
    roleDef: 'Project role definitions',
    projectRoles: 'Project roles',
    newProjectRoles: 'Create new project roles here',
    noProjectDetails: 'No project details available!',
    noUpcomingHolidays: 'No holiday info available!',
    addNewRole: 'Add new role',
    roleCode: 'Role code',
    roleName: 'Role name',
    roleDesc: 'Role description',
    holidayDetails: 'Holiday Details',
    addNewHoliday: 'Add new holiday',
    noData: 'No data to display',
    holidayName: 'Holiday name',
    date: 'Date',
    location: 'Location',
    projectEditSuccess: 'Project edited successfully',
    projectEditFailed: 'Failed to edit project details',
    // validation
    holidayNameReq: 'Holiday name is required',
    holidayDateReq: 'Holiday date is required',
    holidayLocReq: 'Holiday location is required',
    roleNameReq: 'Role name is required',
    roleCodeReq: 'Role code is required',
    roleDescReq: 'Role description is required',
    projectNameReq: 'Project name is required',
    startDateReq: 'Start date is required',
    endDateReq: 'End date is required'
};
