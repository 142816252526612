var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 1.2rem;\n  font: 1.4rem PoppinsSemiBold;\n  color: #5c5b6e;\n  ", "\n  width: 100%;\n"], ["\n  display: flex;\n  margin-bottom: 1.2rem;\n  font: 1.4rem PoppinsSemiBold;\n  color: #5c5b6e;\n  ", "\n  width: 100%;\n"])), function (_a) {
    var customStyles = _a.customStyles;
    return "".concat(customStyles);
});
export default { Label: Label };
var templateObject_1;
