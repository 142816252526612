var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var ShiftIconWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    align-items: center;\n    width: 5.3rem;\n    height: 1.8rem;\n    padding: 0 0.5rem 0.1rem;\n    border-radius: 0.4rem;\n    background-color: ", ";\n"], ["\n    display: flex;\n    align-items: center;\n    width: 5.3rem;\n    height: 1.8rem;\n    padding: 0 0.5rem 0.1rem;\n    border-radius: 0.4rem;\n    background-color: ", ";\n"])), function (_a) {
    var color = _a.color;
    return "".concat(color, "33");
});
var ShiftDot = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    min-width: 0.8rem;\n    height: 0.8rem;\n    border-radius: 0.2rem;\n    background-color: ", ";\n"], ["\n    min-width: 0.8rem;\n    height: 0.8rem;\n    border-radius: 0.2rem;\n    background-color: ", ";\n"])), function (_a) {
    var color = _a.color;
    return color;
});
var ShiftCode = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    font-family: PoppinsRegular;\n    margin: 0 0 0 0.5rem;\n    font-size: 1.2rem;\n    color: #000;\n"], ["\n    font-family: PoppinsRegular;\n    margin: 0 0 0 0.5rem;\n    font-size: 1.2rem;\n    color: #000;\n"])));
export default {
    ShiftIconWrapper: ShiftIconWrapper,
    ShiftDot: ShiftDot,
    ShiftCode: ShiftCode
};
var templateObject_1, templateObject_2, templateObject_3;
