var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import authApi from '@store/api/auth.api';
var initialState = {
    data: {},
    loggedIn: false,
    loading: true,
    error: '',
    callBackCalled: false,
    projects: []
};
export var triggerAuthVerify = createAsyncThunk('auth/triggerAuthVerify', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(authApi.endpoints.loginVerify.initiate())];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var triggerLoginConnect = createAsyncThunk('auth/triggerLoginConnect', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, data, error, isError;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(authApi.endpoints.loginConnect.initiate())];
            case 1:
                _a = _b.sent(), data = _a.data, error = _a.error, isError = _a.isError;
                if (!isError) {
                    window.location.href = data.auth_url;
                }
                else
                    console.log({ error: error });
                return [2 /*return*/];
        }
    });
}); });
export var triggerLoginCallback = createAsyncThunk('auth/triggerLoginCallback', function (_a, _b) {
    var authCode = _a.authCode;
    var dispatch = _b.dispatch, getState = _b.getState;
    return __awaiter(void 0, void 0, void 0, function () {
        var currentState;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, dispatch(authApi.endpoints.loginCallback.initiate({
                        auth_code: authCode,
                        redirect_url: "".concat(!PRODUCTION
                            ? 'http://localhost:3003'
                            : "".concat(process.env.connectRedirectUrl))
                    }))];
                case 1:
                    _c.sent();
                    return [4 /*yield*/, dispatch(authApi.endpoints.projects.initiate({ authCode: authCode }))];
                case 2:
                    _c.sent();
                    currentState = getState();
                    return [4 /*yield*/, dispatch(authApi.endpoints.projectLogin.initiate({
                            project_id: currentState.auth.projects[0].id
                        }))];
                case 3:
                    _c.sent();
                    return [2 /*return*/];
            }
        });
    });
});
export var triggerProjectLogin = createAsyncThunk('auth/triggerProjectLogin', function (_a, thunkApi) {
    var projectId = _a.projectId;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, thunkApi.dispatch(authApi.endpoints.projectLogin.initiate({ project_id: projectId }))];
                case 1:
                    void (_b.sent());
                    return [2 /*return*/];
            }
        });
    });
});
export var triggerTokenRefresh = createAsyncThunk('auth/triggerTokenRefresh', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(authApi.endpoints.refreshToken.initiate())];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var triggerLogout = createAsyncThunk('auth/triggerLogout', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(authApi.endpoints.logout.initiate(undefined, { subscribe: false }))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); });
export var authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuthData: function (state, action) {
            state.data = action.payload.data;
        },
        setLoggedIn: function (state, action) {
            state.loggedIn = action.payload;
        }
    },
    extraReducers: function (builder) {
        builder
            .addMatcher(authApi.endpoints.loginVerify.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.loading = true;
        })
            .addMatcher(authApi.endpoints.loginVerify.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
        })
            .addMatcher(authApi.endpoints.loginVerify.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
            state.loggedIn = true;
        })
            // Callback Matchers
            .addMatcher(authApi.endpoints.loginCallback.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.loading = true;
            state.callBackCalled = true;
        })
            .addMatcher(authApi.endpoints.loginCallback.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
            state.callBackCalled = false;
        })
            .addMatcher(authApi.endpoints.loginCallback.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
            // state.loggedIn = true;
            state.callBackCalled = true;
        })
            // Project List Matchers
            .addMatcher(authApi.endpoints.projects.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.loading = true;
        })
            .addMatcher(authApi.endpoints.projects.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
        })
            .addMatcher(authApi.endpoints.projects.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
            state.loggedIn = true;
            state.projects = payload.result;
        })
            // Project Login Matchers
            .addMatcher(authApi.endpoints.projectLogin.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.loading = true;
        })
            .addMatcher(authApi.endpoints.projectLogin.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
        })
            .addMatcher(authApi.endpoints.projectLogin.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
            state.loggedIn = true;
        })
            // Login Matchers
            .addMatcher(authApi.endpoints.login.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.loading = true;
            state.error = '';
        })
            .addMatcher(authApi.endpoints.login.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
            state.error = 'invalid credentials';
        })
            .addMatcher(authApi.endpoints.login.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
            state.loggedIn = true;
            state.error = '';
        })
            .addMatcher(authApi.endpoints.logout.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.loading = true;
        })
            .addMatcher(authApi.endpoints.logout.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
        })
            .addMatcher(authApi.endpoints.logout.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.loading = false;
            state.loggedIn = false;
        });
    }
});
export var setAuthData = (_a = authSlice.actions, _a.setAuthData), setLoggedIn = _a.setLoggedIn;
export default authSlice.reducer;
