import { ApiMethods } from '@enums/api.enum';
import { api } from './common.api';
import { SHIFT_GENERATION } from '@constants/api.constants';
var shiftGenerationApi = api.injectEndpoints({
    endpoints: function (builder) { return ({
        getShiftGenStatus: builder.query({
            query: function () { return SHIFT_GENERATION.shiftGenStatus; },
            forceRefetch: function () { return true; }
        }),
        initiateSurvey: builder.mutation({
            query: function (body) { return ({
                url: SHIFT_GENERATION.initiateSurvey,
                method: ApiMethods.POST,
                body: body
            }); }
        }),
        getLeaveRequests: builder.query({
            query: function () { return "".concat(SHIFT_GENERATION.leaveRequests); }
        }),
        getUnavailableWeekendRequests: builder.query({
            query: function () { return "".concat(SHIFT_GENERATION.unavailableWeekendRequests); }
        }),
        saveLeaveRequestApprovals: builder.mutation({
            query: function (body) { return ({
                url: SHIFT_GENERATION.leaveRequests,
                method: ApiMethods.PUT,
                body: body
            }); }
        }),
        generateShift: builder.mutation({
            query: function (body) { return ({
                url: SHIFT_GENERATION.generateShift,
                method: ApiMethods.POST,
                body: body
            }); }
        }),
        getShiftSummary: builder.query({
            query: function (period) {
                return "".concat(SHIFT_GENERATION.shiftSummary, "?period=").concat(period);
            },
            forceRefetch: function () { return true; }
        }),
        exportShiftSummary: builder.mutation({
            query: function (body) { return ({
                url: "".concat(SHIFT_GENERATION.exportShiftSummary, "?project_id=").concat(body.projId, "&period=").concat(body.period)
            }); }
        }),
        getShiftReport: builder.query({
            query: function (period) { return "".concat(SHIFT_GENERATION.shiftReport, "?period=").concat(period); }
        }),
        publishSurvey: builder.mutation({
            query: function (body) { return ({
                url: SHIFT_GENERATION.publishSurvey,
                method: ApiMethods.POST,
                body: body
            }); }
        })
    }); }
});
export default shiftGenerationApi;
