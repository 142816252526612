var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@components/Icon';
import Styled from './Sidebar.styled';
// For version managing
import packageJson from '../../../package.json';
var Sidebar = function (props) {
    var _a;
    return _jsxs(Styled.Container, { children: [_jsx(Styled.Logo, {}), _jsx(Styled.LinksWrap, { children: (_a = props.routes) === null || _a === void 0 ? void 0 : _a.map(function (route) {
                    var _a;
                    return _jsxs(Styled.Link, __assign({ to: route.path, icon: route.icon }, { children: [_jsx(Icon, { type: (_a = route.icon) !== null && _a !== void 0 ? _a : '', customStyles: Styled.LinkIconStyles }), _jsx("span", { children: route.label })] }), route.id);
                }) }), _jsx(Styled.Version, { children: packageJson.version })] });
};
export default Sidebar;
