var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import { stringToHslColor } from '@utils';
var Container = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 48px;\n  height: 48px;\n  display: flex;\n  border-radius: 50%;\n  align-items: center;\n  justify-content: center;\n  font-size: 22px;\n  font-weight: 500;\n  color: #fff;\n  ", "\n  z-index: 1;\n"], ["\n  width: 48px;\n  height: 48px;\n  display: flex;\n  border-radius: 50%;\n  align-items: center;\n  justify-content: center;\n  font-size: 22px;\n  font-weight: 500;\n  color: #fff;\n  ", "\n  z-index: 1;\n"])), function (_a) {
    var customBgColor = _a.customBgColor;
    return customBgColor && "\n    background-color: ".concat(customBgColor, ";\n  ");
});
var extractInitials = function (name) {
    return name
        .split(' ')
        .map(function (str) { return str.charAt(0).toUpperCase(); })
        .join('');
};
var Avatar = function (props) {
    return _jsx(Container, __assign({ className: 'avatar-container', customBgColor: stringToHslColor(props.name) }, { children: extractInitials(props.name) }));
};
export default Avatar;
var templateObject_1;
