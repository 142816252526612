var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    headingTooltip: '',
    headerBackButton: {
        hasBackButton: false,
        url: ''
    },
    toast: {
        showToast: false,
        message: '',
        flag: true
    },
    prevRoute: '',
    parentState: ''
};
export var appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        setHeadingTooltip: function (state, action) {
            state.headingTooltip = action.payload;
        },
        clearHeadingTooltip: function (state) {
            state.headingTooltip = '';
        },
        enableBackButtonOnPage: function (state, action) {
            state.headerBackButton.hasBackButton = true;
            state.headerBackButton.url = action.payload; // pass empty string as payload if want to navigate to -1 or pass the previous page route
        },
        disableBackButtonOnPage: function (state) {
            state.headerBackButton.hasBackButton = false;
            state.headerBackButton.url = '';
        },
        setToast: function (state, _a) {
            var 
            // Destructiong action object
            _b = _a.payload, message = _b.message, flag = _b.flag;
            state.toast.showToast = true;
            state.toast.message = message;
            state.toast.flag = flag;
        },
        hideToast: function (state) {
            state.toast.showToast = false;
            state.toast.message = '';
        },
        setPreviousRoute: function (state, _a) {
            var payload = _a.payload;
            state.prevRoute = payload;
        },
        changeParentState: function (state, payload) {
            state.parentState = payload.payload;
            console.log(payload, 'payload');
        },
        clearParentState: function (state) {
            state.parentState = '';
            console.log(state.parentState, 'parentState cleared');
        }
    }
});
export var setHeadingTooltip = (_a = appSlice.actions, _a.setHeadingTooltip), clearHeadingTooltip = _a.clearHeadingTooltip, enableBackButtonOnPage = _a.enableBackButtonOnPage, disableBackButtonOnPage = _a.disableBackButtonOnPage, setToast = _a.setToast, hideToast = _a.hideToast, setPreviousRoute = _a.setPreviousRoute, changeParentState = _a.changeParentState, clearParentState = _a.clearParentState;
export default appSlice.reducer;
