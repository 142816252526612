var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import Header from '@components/Header';
import Sidebar from '@components/Sidebar';
import styled from 'styled-components';
import { Outlet, useNavigate } from 'react-router-dom';
import routes from '@constants/route.constants';
import { useAppSelector } from '@store';
import media from '@utils/media';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-grow: 1;\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-grow: 1;\n"])));
var ContentWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column;\n  flex-grow: 1;\n  background-color: var(--body-bg-color);\n  ", "\n"], ["\n  display: flex;\n  flex-flow: column;\n  flex-grow: 1;\n  background-color: var(--body-bg-color);\n  ", "\n"])), media.lessThan('sm')(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    max-width: 85%;\n  "], ["\n    max-width: 85%;\n  "]))));
var OutletWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: calc(100% - 9rem);\n"], ["\n  height: calc(100% - 9rem);\n"])));
var sidebarRoutes = [
    routes.DASHBOARD,
    routes.PROJECT_DETAILS,
    routes.SHIFT_DETAILS,
    routes.TEAM_MEMBERS
];
var ProtectedLayout = function () {
    var navigate = useNavigate();
    var loggedIn = useAppSelector(function (state) { return state.auth; }).loggedIn;
    useEffect(function () {
        if (!loggedIn) {
            navigate(routes.LOGIN.path); // Redirect to login page if not logged in.
        }
    }, []);
    var role = useAppSelector(function (state) { return state.user.data.role; });
    var navRoutes = role === 'member' ? sidebarRoutes.filter(function (route) { return route !== routes.SHIFT_DETAILS; }) : sidebarRoutes;
    return (_jsxs(Container, { children: [_jsx(Sidebar, { routes: navRoutes }), _jsxs(ContentWrap, { children: [_jsx(Header, {}), _jsx(OutletWrap, { children: _jsx(Outlet, {}) })] })] }));
};
export default ProtectedLayout;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
