import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_BASE_PATH, API_VERSION } from '@constants/api.constants';
var baseQuery = fetchBaseQuery({
    baseUrl: "".concat(API_BASE_PATH, "/").concat(API_VERSION)
});
export var api = createApi({
    reducerPath: 'api',
    baseQuery: baseQuery,
    endpoints: function () { return ({}); }
});
