var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import memberUnavailableApi from '@store/api/memberUnavailable.api';
import _ from 'lodash';
var initialState = {
    isLoading: false,
    data: {},
    error: {},
    shiftByDate: {},
    unAvailableHistory: [],
    markUnavailabilityError: {}
};
var getError = function (code) {
    switch (code) {
        case 409000:
            return 'Entry already exists. Please select another date.';
        default:
            return 'Marking unavailability failed. Please try again.';
    }
};
export var fetchMemberUnavailableHistory = createAsyncThunk('memberUnavailable/fetchMemberUnavailableHistory', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(memberUnavailableApi.endpoints.getMemberUnavailableHistory.initiate())];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var fetchShiftByDate = createAsyncThunk('memberUnavailable/fetchShiftByDate', function (date, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(memberUnavailableApi.endpoints.getShiftByDate.initiate(date))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var markUnavailablity = createAsyncThunk('memberUnavailable/markUnavailablity', function (body, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(memberUnavailableApi.endpoints.postUnavailability.initiate(body))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var memberUnavailableSlice = createSlice({
    name: 'member-unavailable',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addMatcher(memberUnavailableApi.endpoints.getMemberUnavailableHistory.matchPending, function (state) {
            state.isLoading = true;
        })
            .addMatcher(memberUnavailableApi.endpoints.getMemberUnavailableHistory.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.isLoading = false;
            state.error = payload === null || payload === void 0 ? void 0 : payload.data;
            state.data = {};
        })
            .addMatcher(memberUnavailableApi.endpoints.getMemberUnavailableHistory.matchFulfilled, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.isLoading = false;
            state.error = '';
            state.unAvailableHistory = (_b = payload.result) === null || _b === void 0 ? void 0 : _b.history;
        });
        builder.addMatcher(memberUnavailableApi.endpoints.getShiftByDate.matchPending, function (state) {
            state.isLoading = true;
        })
            .addMatcher(memberUnavailableApi.endpoints.getShiftByDate.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.isLoading = false;
            state.error = payload === null || payload === void 0 ? void 0 : payload.data;
            state.data = {};
        })
            .addMatcher(memberUnavailableApi.endpoints.getShiftByDate.matchFulfilled, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.isLoading = false;
            state.error = '';
            state.shiftByDate = !_.isNull((_b = payload === null || payload === void 0 ? void 0 : payload.result) === null || _b === void 0 ? void 0 : _b.shifts) ? payload.result.shifts[0] : null;
        });
        builder.addMatcher(memberUnavailableApi.endpoints.postUnavailability.matchPending, function (state) {
            state.isLoading = true;
        })
            .addMatcher(memberUnavailableApi.endpoints.postUnavailability.matchRejected, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.isLoading = false;
            state.markUnavailabilityError = __assign(__assign({}, payload === null || payload === void 0 ? void 0 : payload.data), { message: getError((_b = payload === null || payload === void 0 ? void 0 : payload.data) === null || _b === void 0 ? void 0 : _b.status_code) });
            state.data = {};
        })
            .addMatcher(memberUnavailableApi.endpoints.postUnavailability.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isLoading = false;
            state.markUnavailabilityError = {};
            state.data = payload === null || payload === void 0 ? void 0 : payload.result;
        });
    }
});
export default memberUnavailableSlice.reducer;
