import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet, useSearchParams, useNavigate, useLocation, useMatches } from 'react-router-dom';
import routes from '@constants/route.constants';
import PreLoader from '@components/PreLoader';
import { useAppDispatch, useAppSelector } from '@store';
import { setAuthData, triggerAuthVerify, triggerLoginCallback } from '@store/features/auth';
import { useEffect, useMemo } from 'react';
import { fetchUserData } from '@store/features/user';
import { findOccurenceValue } from '@utils';
import { Role } from '@enums/role.enum';
import { setPreviousRoute } from '@store/features/app';
var RootLayout = function (props) {
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var location = useLocation();
    var searchParams = useSearchParams()[0];
    var code = searchParams.get('code');
    var state = searchParams.get('state');
    var matches = useMatches();
    var user = useAppSelector(function (state) { return state.user.data; });
    var userPermissions = useMemo(function () {
        var _a, _b;
        var rolePermissions = findOccurenceValue(matches, ['handle', 'permissions'], true);
        return {
            read: (_a = rolePermissions.read.some(function (el) { var _a; return el === ((_a = user === null || user === void 0 ? void 0 : user.role) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || el === Role.USER; })) !== null && _a !== void 0 ? _a : true,
            write: (_b = rolePermissions.write.some(function (el) { var _a; return el === ((_a = user === null || user === void 0 ? void 0 : user.role) === null || _a === void 0 ? void 0 : _a.toUpperCase()) || el === Role.USER; })) !== null && _b !== void 0 ? _b : true
        };
    }, [matches, user]);
    useEffect(function () {
        if (!userPermissions.read) {
            navigate(routes.DASHBOARD.path, { replace: true });
        }
    }, [userPermissions]);
    var authData = useAppSelector(function (state) { return state.auth.data; });
    var isLoggedIn = useAppSelector(function (state) { return state.auth.loggedIn; });
    var loading = useAppSelector(function (state) { return state.auth.loading; });
    var callBackCalled = useAppSelector(function (state) { return state.auth.callBackCalled; });
    useEffect(function () {
        if (!code) {
            void dispatch(triggerAuthVerify());
        }
    }, []);
    useEffect(function () {
        if (code && code !== (authData === null || authData === void 0 ? void 0 : authData.code) && !callBackCalled) {
            dispatch(setAuthData({ state: state, code: code }));
            void dispatch(triggerLoginCallback({
                authCode: code
            }));
        }
    }, [code, authData]);
    useEffect(function () {
        if (!loading) {
            if (isLoggedIn) {
                void dispatch(fetchUserData());
                var redirectUri = localStorage.getItem('redirectUri');
                navigate(location.pathname === '/' || location.pathname === '/login' || !userPermissions.read
                    ? (redirectUri !== null && redirectUri !== void 0 ? redirectUri : routes.DASHBOARD.path)
                    : "".concat(location.pathname).concat(location.search ? location.search : ''), { replace: true });
                localStorage.removeItem('redirectUri');
            }
            else {
                navigate(routes.LOGIN.path, { replace: true });
            }
        }
    }, [isLoggedIn, loading]);
    useEffect(function () {
        if (!location.pathname.includes(routes.LOGIN.path) && location.pathname !== '/') {
            dispatch(setPreviousRoute(location.pathname));
        }
    }, [location]);
    if (loading) {
        return _jsx(PreLoader, {});
    }
    return _jsx(Outlet, {});
};
export default RootLayout;
