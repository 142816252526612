var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var Toast = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 50rem;\n  height: 7.6rem;\n  padding: 1.3rem 2.6rem 1.5rem 1.8rem;\n  border-radius: 8px;\n  background-color: ", ";\n  border-left: 8px solid ", ";\n  position: absolute;\n  top: 23rem;\n  right: 5.6rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  z-index: 9999;\n"], ["\n  width: 50rem;\n  height: 7.6rem;\n  padding: 1.3rem 2.6rem 1.5rem 1.8rem;\n  border-radius: 8px;\n  background-color: ", ";\n  border-left: 8px solid ", ";\n  position: absolute;\n  top: 23rem;\n  right: 5.6rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  z-index: 9999;\n"])), function (_a) {
    var type = _a.type;
    return type === 'Success' ? '#cdf7df' : '#f7cdcd';
}, function (_a) {
    var type = _a.type;
    return type === 'Success' ? '#40ad70' : '#ad4040';
});
var ToastInnerDiv = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var TextWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-left: 1.8rem;\n"], ["\n  margin-left: 1.8rem;\n"])));
var Text = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  &.text-status {\n    font: 1.6rem PoppinsSemiBold;\n    color: ", ";\n  }\n  &.text-message {\n    font: 1.6rem PoppinsRegular;\n    color: ", ";\n  }\n"], ["\n  &.text-status {\n    font: 1.6rem PoppinsSemiBold;\n    color: ", ";\n  }\n  &.text-message {\n    font: 1.6rem PoppinsRegular;\n    color: ", ";\n  }\n"])), function (_a) {
    var color = _a.color;
    return color;
}, function (_a) {
    var color = _a.color;
    return color;
});
var CloseBtn = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
export default {
    Toast: Toast,
    TextWrap: TextWrap,
    Text: Text,
    ToastInnerDiv: ToastInnerDiv,
    CloseBtn: CloseBtn
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
