var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
var InputField = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font: 14px PoppinsRegular;\n  color: #6e6c7d;\n  border: 2px solid #d8d8d8;\n  border-radius: 10px;\n  padding: 13px 13px 13px 15px;\n  accent-color: var(--primary-color);\n  opacity: 0.8;\n  :focus{\n    border: 2px solid var(--primary-color);\n    outline: none;\n  }\n  ::placeholder{\n    color: #6e6c7d;\n    font: 14px PoppinsRegular;\n  }\n  ::-webkit-datetime-edit {\n    text-transform: ", "\n  }\n  ", "\n"], ["\n  font: 14px PoppinsRegular;\n  color: #6e6c7d;\n  border: 2px solid #d8d8d8;\n  border-radius: 10px;\n  padding: 13px 13px 13px 15px;\n  accent-color: var(--primary-color);\n  opacity: 0.8;\n  :focus{\n    border: 2px solid var(--primary-color);\n    outline: none;\n  }\n  ::placeholder{\n    color: #6e6c7d;\n    font: 14px PoppinsRegular;\n  }\n  ::-webkit-datetime-edit {\n    text-transform: ", "\n  }\n  ", "\n"])), function (props) { return props.type === 'date' ? 'uppercase' : 'none'; }, function (_a) {
    var customStyles = _a.customStyles;
    return "".concat(customStyles);
});
var Input = function (props) {
    var type = props.type, customStyles = props.customStyles, placeholder = props.placeholder, id = props.id, name = props.name, register = props.register, required = props.required, min = props.min, max = props.max, onChange = props.onChange, disabled = props.disabled, value = props.value;
    return _jsx(_Fragment, { children: _jsx(InputField, __assign({ type: type, placeholder: placeholder, customStyles: customStyles, id: id }, register(name, { required: required }), { min: min, max: max, onChange: onChange, disabled: disabled, value: value })) });
};
export default Input;
var templateObject_1;
