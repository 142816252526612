import { api } from './common.api';
import { MONTHLY_SCHEDULE, SCHEDULED_HISTORY } from '@constants/api.constants';
import { ApiMethods } from '@enums/api.enum';
var monthlyScheduleApi = api.injectEndpoints({
    endpoints: function (builder) { return ({
        getMonthlySchedule: builder.query({
            query: function (month) {
                return "".concat(MONTHLY_SCHEDULE.monthlySchedule, "?period=").concat(month);
            },
            forceRefetch: function () { return true; }
        }),
        getProjectMembers: builder.query({
            query: function () { return MONTHLY_SCHEDULE.teamMembers; }
        }),
        getScheduledHistory: builder.query({
            query: function () { return SCHEDULED_HISTORY.scheduledHistory; }
        }),
        swapShifts: builder.mutation({
            query: function (body) { return ({
                url: "".concat(MONTHLY_SCHEDULE.swapShifts, "?period=").concat(body.period),
                method: ApiMethods.POST,
                body: body
            }); }
        })
    }); }
});
export var useGetMonthlyScheduleQuery = monthlyScheduleApi.useGetMonthlyScheduleQuery;
export default monthlyScheduleApi;
