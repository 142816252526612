var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Avatar from '@components/Avatar';
import styled from 'styled-components';
import CaretDown from '@assets/icons/caret_down.webp';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '@store';
import routes from '@constants/route.constants';
import { useNavigate } from 'react-router-dom';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  position: relative;\n  overflow: hidden;\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  position: relative;\n  overflow: hidden;\n  ", "\n"])), function (_a) {
    var isMenuOpen = _a.isMenuOpen;
    return isMenuOpen && "\n    overflow: visible;\n    ".concat(Caret, " {\n      transform: rotate(180deg);\n    }\n    ").concat(MenuContainer, " {\n      transition-delay: .1s;\n      opacity: 1;\n      transform: translateY(0);\n    }\n  ");
});
var Caret = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 24px;\n  height: 24px;\n  background: url(", ") no-repeat;\n  background-size: contain;\n  margin-left: 12px;\n  transition: 0.3s;\n"], ["\n  width: 24px;\n  height: 24px;\n  background: url(", ") no-repeat;\n  background-size: contain;\n  margin-left: 12px;\n  transition: 0.3s;\n"])), CaretDown);
var MenuContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 15rem;\n  height: 10rem;\n  position: absolute;\n  top: calc(100% + 5px);\n  right: 0;\n  background-color: #fff;\n  border-radius: 10px;\n  opacity: 0;\n  transform: translateY(-10px);\n  transition: 0.3s;\n  box-shadow: 0px 10px 13px 0px #0000001c;\n  display: flex;\n  flex-flow: column;\n  z-index: 10;\n"], ["\n  width: 15rem;\n  height: 10rem;\n  position: absolute;\n  top: calc(100% + 5px);\n  right: 0;\n  background-color: #fff;\n  border-radius: 10px;\n  opacity: 0;\n  transform: translateY(-10px);\n  transition: 0.3s;\n  box-shadow: 0px 10px 13px 0px #0000001c;\n  display: flex;\n  flex-flow: column;\n  z-index: 10;\n"])));
var MenuOption = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  align-items: center;\n  padding: 0 2rem;\n  font-size: 14px;\n  cursor: pointer;\n  /* justify-content: center; */\n"], ["\n  flex: 1;\n  display: flex;\n  align-items: center;\n  padding: 0 2rem;\n  font-size: 14px;\n  cursor: pointer;\n  /* justify-content: center; */\n"])));
var menuOptions = {
    member: [
        {
            id: routes.MY_PROFILE.id,
            label: routes.MY_PROFILE.label,
            route: routes.MY_PROFILE.path
        },
        {
            id: 'logout',
            label: 'Logout'
        }
    ],
    admin: [
        {
            id: routes.MY_PROFILE.id,
            label: routes.MY_PROFILE.label,
            route: routes.MY_PROFILE.path
        },
        {
            id: 'logout',
            label: 'Logout'
        }
    ]
};
var AvatarMenu = function (props) {
    var _a;
    var navigate = useNavigate();
    var roleMenuOptions = useMemo(function () { return menuOptions[props.role].map(function (el) {
        if (el.id === 'logout') {
            return __assign(__assign({}, el), { cb: props.logout });
        }
        ;
        return el;
    }); }, [props.role, props.logout]);
    var _b = useState(false), isMenuOpen = _b[0], setIsMenuOpen = _b[1];
    var userData = useAppSelector(function (state) { return state.user.data; });
    var menuOnClickHandler = function (event) {
        setIsMenuOpen(!isMenuOpen);
        event.stopPropagation();
    };
    useEffect(function () {
        var handleOutsideClick = function (event) {
            setIsMenuOpen(false);
        };
        document.addEventListener('click', handleOutsideClick);
        return function () {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);
    return (_jsxs(Container, __assign({ isMenuOpen: isMenuOpen, onClick: menuOnClickHandler }, { children: [_jsx(Avatar, { name: (_a = userData.name) !== null && _a !== void 0 ? _a : '' }), _jsx(Caret, {}), _jsx(MenuContainer, { children: roleMenuOptions.map(function (el) { return _jsx(MenuOption, __assign({ onClick: function () { if (el.cb)
                        el.cb();
                    else if (el === null || el === void 0 ? void 0 : el.route)
                        navigate(el.route); } }, { children: el.label }), el.id); }) })] })));
};
export default AvatarMenu;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
