var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Styled from './Label.styled';
;
var Label = function (props) { return _jsx(Styled.Label, __assign({ htmlFor: props.for, className: 'label', customStyles: props.customStyles }, { children: props.children })); };
export default Label;
