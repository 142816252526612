import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import AppRoutes from './AppRoutes';
import GlobalStyles from './GlobalStyles';
import Toast from '@core-components/Toast';
import { useAppSelector } from '@store';
var App = function () {
    var _a = useAppSelector(function (state) { return state.app.toast; }), showToast = _a.showToast, message = _a.message, flag = _a.flag;
    return (_jsxs(_Fragment, { children: [_jsx(GlobalStyles, {}), _jsx(AppRoutes, {}), showToast && (_jsx(Toast, { message: message, type: flag ? 'Success' : 'Failed' }))] }));
};
export default App;
