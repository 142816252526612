export default {
    submitApiFailure: 'Something went wrong! Please try again.',
    invalidRequest: 'Invalid Request! Please try again.',
    serverError: 'Unexpected server error! Please try again.',
    invalidStatus: 'Invalid status!',
    validityError: 'Request Validity Error!',
    preferenceUpdateFailure: 'No preferences updated!'
};
export var mockData = [
    {
        date: '2024-03-01T00:00:00Z',
        report: [
            {
                shift_id: 7,
                member_count: 28
            },
            {
                shift_id: 2,
                member_count: 11
            },
            {
                shift_id: 6,
                member_count: 4
            },
            {
                shift_id: 5,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 4,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            }
        ]
    },
    {
        date: '2024-03-02T00:00:00Z',
        report: [
            {
                shift_id: 10,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 1'
                ]
            },
            {
                shift_id: 8,
                member_count: 3
            },
            {
                shift_id: 9,
                member_count: 4
            }
        ]
    },
    {
        date: '2024-03-03T00:00:00Z',
        report: [
            {
                shift_id: 10,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 1'
                ]
            },
            {
                shift_id: 9,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 8,
                member_count: 3
            }
        ]
    },
    {
        date: '2024-03-04T00:00:00Z',
        report: [
            {
                shift_id: 2,
                member_count: 6
            },
            {
                shift_id: 7,
                member_count: 33
            },
            {
                shift_id: 6,
                member_count: 3
            },
            {
                shift_id: 5,
                member_count: 3
            }
        ]
    },
    {
        date: '2024-03-05T00:00:00Z',
        report: [
            {
                shift_id: 6,
                member_count: 4
            },
            {
                shift_id: 2,
                member_count: 5
            },
            {
                shift_id: 5,
                member_count: 4
            },
            {
                shift_id: 4,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 1,
                member_count: 1
            }
        ]
    },
    {
        date: '2024-03-06T00:00:00Z',
        report: [
            {
                shift_id: 1,
                member_count: 1
            },
            {
                shift_id: 7,
                member_count: 28
            },
            {
                shift_id: 6,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 2,
                member_count: 10
            },
            {
                shift_id: 4,
                member_count: 4
            }
        ]
    },
    {
        date: '2024-03-07T00:00:00Z',
        report: [
            {
                shift_id: 5,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 4,
                member_count: 4
            },
            {
                shift_id: 1,
                member_count: 4
            },
            {
                shift_id: 7,
                member_count: 28
            },
            {
                shift_id: 6,
                member_count: 3
            }
        ]
    },
    {
        date: '2024-03-08T00:00:00Z',
        report: [
            {
                shift_id: 3,
                member_count: 35
            },
            {
                shift_id: 13,
                member_count: 2
            },
            {
                shift_id: 5,
                member_count: 4
            },
            {
                shift_id: 4,
                member_count: 4
            },
            {
                shift_id: 6,
                member_count: 1,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 1'
                ]
            }
        ]
    },
    {
        date: '2024-03-09T00:00:00Z',
        report: [
            {
                shift_id: 10,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 1'
                ]
            },
            {
                shift_id: 9,
                member_count: 3
            },
            {
                shift_id: 8,
                member_count: 3
            }
        ]
    },
    {
        date: '2024-03-10T00:00:00Z',
        report: [
            {
                shift_id: 10,
                member_count: 4
            },
            {
                shift_id: 9,
                member_count: 4
            },
            {
                shift_id: 8,
                member_count: 4
            }
        ]
    },
    {
        date: '2024-03-11T00:00:00Z',
        report: [
            {
                shift_id: 4,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 7,
                member_count: 24
            },
            {
                shift_id: 2,
                member_count: 15
            },
            {
                shift_id: 6,
                member_count: 3
            },
            {
                shift_id: 1,
                member_count: 1
            }
        ]
    },
    {
        date: '2024-03-12T00:00:00Z',
        report: [
            {
                shift_id: 4,
                member_count: 4
            },
            {
                shift_id: 5,
                member_count: 3
            },
            {
                shift_id: 7,
                member_count: 31
            },
            {
                shift_id: 2,
                member_count: 6
            },
            {
                shift_id: 6,
                member_count: 3
            }
        ]
    },
    {
        date: '2024-03-13T00:00:00Z',
        report: [
            {
                shift_id: 6,
                member_count: 3
            },
            {
                shift_id: 2,
                member_count: 6
            },
            {
                shift_id: 1,
                member_count: 1
            },
            {
                shift_id: 4,
                member_count: 4
            },
            {
                shift_id: 5,
                member_count: 3
            }
        ]
    },
    {
        date: '2024-03-14T00:00:00Z',
        report: [
            {
                shift_id: 2,
                member_count: 12
            },
            {
                shift_id: 6,
                member_count: 3
            },
            {
                shift_id: 1,
                member_count: 1
            },
            {
                shift_id: 5,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 4,
                member_count: 4
            }
        ]
    },
    {
        date: '2024-03-15T00:00:00Z',
        report: [
            {
                shift_id: 6,
                member_count: 4
            },
            {
                shift_id: 2,
                member_count: 18
            },
            {
                shift_id: 1,
                member_count: 1
            },
            {
                shift_id: 5,
                member_count: 4
            },
            {
                shift_id: 4,
                member_count: 4
            }
        ]
    },
    {
        date: '2024-03-16T00:00:00Z',
        report: [
            {
                shift_id: 8,
                member_count: 3
            },
            {
                shift_id: 9,
                member_count: 3
            },
            {
                shift_id: 3,
                member_count: 40
            }
        ]
    },
    {
        date: '2024-03-17T00:00:00Z',
        report: [
            {
                shift_id: 8,
                member_count: 4
            },
            {
                shift_id: 9,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 3,
                member_count: 39
            }
        ]
    },
    {
        date: '2024-03-18T00:00:00Z',
        report: [
            {
                shift_id: 7,
                member_count: 36
            },
            {
                shift_id: 6,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 5,
                member_count: 4
            },
            {
                shift_id: 4,
                member_count: 4
            }
        ]
    },
    {
        date: '2024-03-19T00:00:00Z',
        report: [
            {
                shift_id: 6,
                member_count: 3
            },
            {
                shift_id: 2,
                member_count: 1
            },
            {
                shift_id: 4,
                member_count: 4
            },
            {
                shift_id: 5,
                member_count: 3
            }
        ]
    },
    {
        date: '2024-03-20T00:00:00Z',
        report: [
            {
                shift_id: 2,
                member_count: 1
            },
            {
                shift_id: 7,
                member_count: 37
            },
            {
                shift_id: 6,
                member_count: 3
            },
            {
                shift_id: 5,
                member_count: 4
            }
        ]
    },
    {
        date: '2024-03-21T00:00:00Z',
        report: [
            {
                shift_id: 4,
                member_count: 4
            },
            {
                shift_id: 5,
                member_count: 4,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 2,
                member_count: 1
            },
            {
                shift_id: 7,
                member_count: 36
            }
        ]
    },
    {
        date: '2024-03-22T00:00:00Z',
        report: [
            {
                shift_id: 7,
                member_count: 36
            },
            {
                shift_id: 2,
                member_count: 1
            },
            {
                shift_id: 6,
                member_count: 4
            },
            {
                shift_id: 5,
                member_count: 3
            },
            {
                shift_id: 4,
                member_count: 4
            }
        ]
    },
    {
        date: '2024-03-23T00:00:00Z',
        report: [
            {
                shift_id: 8,
                member_count: 3
            },
            {
                shift_id: 9,
                member_count: 4
            },
            {
                shift_id: 7,
                member_count: 1
            },
            {
                shift_id: 3,
                member_count: 37
            }
        ]
    },
    {
        date: '2024-03-24T00:00:00Z',
        report: [
            {
                shift_id: 10,
                member_count: 4
            },
            {
                shift_id: 9,
                member_count: 4
            },
            {
                shift_id: 8,
                member_count: 4
            },
            {
                shift_id: 7,
                member_count: 1
            }
        ]
    },
    {
        date: '2024-03-25T00:00:00Z',
        report: [
            {
                shift_id: 1,
                member_count: 1
            },
            {
                shift_id: 6,
                member_count: 4
            },
            {
                shift_id: 5,
                member_count: 3
            },
            {
                shift_id: 4,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 2,
                member_count: 2
            },
            {
                shift_id: 3,
                member_count: 2
            }
        ]
    },
    {
        date: '2024-03-26T00:00:00Z',
        report: [
            {
                shift_id: 2,
                member_count: 4
            },
            {
                shift_id: 6,
                member_count: 4
            },
            {
                shift_id: 4,
                member_count: 3
            },
            {
                shift_id: 5,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 7,
                member_count: 33
            }
        ]
    },
    {
        date: '2024-03-27T00:00:00Z',
        report: [
            {
                shift_id: 7,
                member_count: 30
            },
            {
                shift_id: 1,
                member_count: 4
            },
            {
                shift_id: 6,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            },
            {
                shift_id: 4,
                member_count: 4
            },
            {
                shift_id: 5,
                member_count: 3
            }
        ]
    },
    {
        date: '2024-03-28T00:00:00Z',
        report: [
            {
                shift_id: 4,
                member_count: 4
            },
            {
                shift_id: 5,
                member_count: 4
            },
            {
                shift_id: 7,
                member_count: 30
            },
            {
                shift_id: 1,
                member_count: 6
            },
            {
                shift_id: 2,
                member_count: 1
            }
        ]
    },
    {
        date: '2024-03-29T00:00:00Z',
        report: [
            {
                shift_id: 3,
                member_count: 38
            },
            {
                shift_id: 13,
                member_count: 3
            },
            {
                shift_id: 11,
                member_count: 4
            },
            {
                shift_id: 12,
                member_count: 3
            }
        ]
    },
    {
        date: '2024-03-30T00:00:00Z',
        report: [
            {
                shift_id: 10,
                member_count: 4
            },
            {
                shift_id: 8,
                member_count: 4
            },
            {
                shift_id: 9,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            }
        ]
    },
    {
        date: '2024-03-31T00:00:00Z',
        report: [
            {
                shift_id: 10,
                member_count: 4
            },
            {
                shift_id: 8,
                member_count: 4
            },
            {
                shift_id: 9,
                member_count: 3,
                error_string: [
                    'Insufficient number of employees for role L2 expected minimum : 4 current member count : 3'
                ]
            }
        ]
    }
];
