/**
 * @description Converts string to an HSL color
 * @param {string} str Random String
 * @param {string | number} s Saturation
 * @param {string | number} l Lightness
 * @returns HSL Color based on the saturation and lightness and the string provided
 */
export var stringToHslColor = function (str, s, l) {
    if (s === void 0) { s = 80; }
    if (l === void 0) { l = 70; }
    var hash = 9;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var h = hash % 360;
    return "hsl(".concat(h, ",").concat(s, "%,").concat(l, "%)");
};
/**
 * @description Traverses and finds the first occurence of value present
 * @param {any[]} arr
 * @param {string[]} pathArr
 * @param {boolean} reverse
 */
export var findOccurenceValue = function (arr, pathArr, reverse) {
    if (reverse === void 0) { reverse = false; }
    var traverse = function (val, keys) {
        if (!val)
            return null;
        var newVal = val[keys[0]];
        if (keys.length === 1) {
            return newVal;
        }
        return traverse(newVal, keys.slice(1));
    };
    var el = reverse
        ? arr.reverse().find(function (e) { return traverse(e, pathArr); })
        : arr.find(function (e) { return traverse(e, pathArr); });
    return traverse(el, pathArr);
};
export var convertToZulu = function (time) {
    var date = new Date("0000-01-01 ".concat(time));
    return date.toISOString();
};
