/* eslint-disable @typescript-eslint/indent */
export var API_BASE_PATH = '/api';
export var API_VERSION = 'v1';
export var ROLE_PATHS = {
    member: 'member',
    admin: 'admin'
};
export var AUTH_PATHS = {
    verify: '/user/verify',
    connect: '/user/connect',
    callback: '/user/callback',
    refresh: '/user/refresh',
    logout: '/user/logout',
    login: '/user/login',
    projectLogin: '/user/project-login',
    projects: '/user/projects'
};
export var USER_PATHS = {
    getUser: '/user/profile',
    editPhoneNumber: '/user/phone-number'
};
export var MONTHLY_PREFERENCES_PATHS = {
    monthlyPreferences: '/project/monthly-preferences?type=leave',
    deletePreferences: '/project/monthly-preferences',
    monthlyPreferencesDeadline: '/project/deadline?type=monthly_preference',
    unavailableWeekend: '/project/monthly-preferences?type=unavailable_weekend',
    shiftPreferencesDeadline: '/project/deadline?type=shift_preference',
    leavePreferences: 'project/leave-preferences',
    shiftPreferences: 'project/shift-preferences',
    createMonthlyPreference: '/project/monthly-preferences'
};
export var MEMBER_UNAVAILABLE_PATHS = {
    shift: '/user/shifts',
    markUnavailability: '/project/unavailability'
};
export var PROJECT_DETAILS_PATH = {
    project: '/project',
    roles: '/project/roles',
    shifts: '/project/shifts',
    holidays: '/project/holidays'
};
export var MONTHLY_SCHEDULE = {
    monthlySchedule: '/project/calendar/schedule',
    teamMembers: '/project/members',
    swapShifts: '/shifts/swap/'
};
// to get the scheduled shift history months
export var SCHEDULED_HISTORY = {
    scheduledHistory: '/project/calendar/archive'
};
export var DAY_SCHEDULE = {
    daySchedule: '/project/calendar/day'
};
export var HELP_LINKS = {
    helpLinks: '/help-links'
};
export var TEAM_DETAILS_ADMIM_PATHS = {
    members: '/members',
    initiateSurvey: '/members/initiate-survey',
    includeInGeneration: '/members/include-in-generation'
};
export var SHIFT_DETAILS = {
    shifts: '/shifts'
};
export var SHIFT_GENERATION = {
    shiftGenStatus: '/project/generate-shift/status',
    initiateSurvey: '/project/generate-shift/initiate-survey',
    shiftReport: '/project/shift-report',
    leaveRequests: '/project/generate-shift/leave-requests?type=leave',
    unavailableWeekendRequests: '/project/generate-shift/leave-requests?type=unavailable_weekend',
    shiftSummary: '/project/shift-summary',
    generateShift: '/project/generate-shift',
    exportShiftSummary: '/project/schedule',
    publishSurvey: '/project/publish-shift'
};
