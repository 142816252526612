var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Styled from './PopUp.styled';
var BtnType = {
    confirm: 'Confirm',
    edit: 'Save',
    delete: 'Remove',
    alert: 'OK'
};
var PopUp = function (props) {
    var children = props.children, type = props.type, title = props.title, onCancel = props.onCancel, onConfirm = props.onConfirm, customStyles = props.customStyles, width = props.width;
    return (_jsx(Styled.Container, { children: _jsxs(Styled.Wrapper, __assign({ className: 'popup', customStyles: customStyles, style: { width: width } }, { children: [_jsxs(Styled.Wrapper, __assign({ className: 'contentWrapper', customStyles: customStyles }, { children: [_jsx(Styled.Title, { children: title }), children] })), _jsxs(Styled.Wrapper, __assign({ className: 'buttonWrapper' }, { children: [_jsx(Styled.Button, __assign({ className: 'cancel', type: 'submit', onClick: onCancel }, { children: "Cancel" })), _jsx(Styled.Button, __assign({ className: 'confirm', type: 'submit', onClick: onConfirm }, { children: BtnType[type] }))] }))] })) }));
};
export default PopUp;
