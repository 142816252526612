import { api } from './common.api';
import { ApiMethods } from '@enums/api.enum';
import { TEAM_DETAILS_ADMIM_PATHS } from '@constants/api.constants';
var teamDetailsAdminApi = api.injectEndpoints({
    endpoints: function (builder) { return ({
        getMembers: builder.query({
            query: function () { return TEAM_DETAILS_ADMIM_PATHS.members; },
            forceRefetch: function () { return true; }
        }),
        putMemberDetails: builder.mutation({
            query: function (body) { return ({
                url: TEAM_DETAILS_ADMIM_PATHS.members,
                method: ApiMethods.PUT,
                body: body
            }); }
        }),
        initiateSurvey: builder.mutation({
            query: function (body) { return ({
                url: TEAM_DETAILS_ADMIM_PATHS.initiateSurvey,
                method: ApiMethods.POST,
                body: body
            }); }
        }),
        includeInGeneration: builder.query({
            query: function (body) { return ({
                url: TEAM_DETAILS_ADMIM_PATHS.includeInGeneration,
                method: ApiMethods.PUT,
                body: body
            }); }
        })
    }); }
});
export default teamDetailsAdminApi;
