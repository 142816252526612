import { api } from './common.api';
import { API_BASE_PATH, API_VERSION, AUTH_PATHS } from '@constants/api.constants';
import { ApiMethods } from '@enums/api.enum';
export var authApi = api.injectEndpoints({
    endpoints: function (build) { return ({
        // Login Verify API
        loginVerify: build.query({
            query: function () { return AUTH_PATHS.verify; }
        }),
        // Login Connect API
        loginConnect: build.query({
            query: function () { return "".concat(API_BASE_PATH, "/").concat(API_VERSION).concat(AUTH_PATHS.connect, "?redirect_url=").concat(!PRODUCTION ? 'http://localhost:3003' : "".concat(process.env.connectRedirectUrl)); },
            transformResponse: function (response) { return response.result; }
        }),
        // Login Callback API
        loginCallback: build.mutation({
            query: function (body) { return ({
                url: AUTH_PATHS.callback,
                method: ApiMethods.POST,
                body: body
            }); }
        }),
        // Project Login API
        projectLogin: build.mutation({
            query: function (body) { return ({
                url: AUTH_PATHS.projectLogin,
                method: ApiMethods.POST,
                body: body
            }); }
        }),
        // Project Login API
        projects: build.mutation({
            query: function (authCode) { return ({
                url: "".concat(AUTH_PATHS.projects, "?authCode=").concat(authCode),
                method: ApiMethods.GET,
            }); },
        }),
        // Refresh Token API
        refreshToken: build.query({
            query: function () { return AUTH_PATHS.refresh; }
        }),
        // Logout API
        logout: build.query({
            query: function () { return AUTH_PATHS.logout; }
        }),
        // Login API
        login: build.mutation({
            query: function (body) { return ({
                url: AUTH_PATHS.login,
                method: ApiMethods.POST,
                body: body
            }); }
        })
    }); }
});
export var useLoginVerifyQuery = authApi.useLoginVerifyQuery, useLoginConnectQuery = authApi.useLoginConnectQuery, useLoginCallbackMutation = authApi.useLoginCallbackMutation, useLoginMutation = authApi.useLoginMutation;
export default authApi;
;
