import { api } from './common.api';
import { MONTHLY_PREFERENCES_PATHS } from '@constants/api.constants';
import { ApiMethods } from '@enums/api.enum';
export var preferencesApi = api.injectEndpoints({
    endpoints: function (build) { return ({
        getMonthlyPreferences: build.mutation({
            query: function () { return MONTHLY_PREFERENCES_PATHS.monthlyPreferences; }
        }),
        createMonthlyPreferences: build.mutation({
            query: function (body) { return ({
                url: MONTHLY_PREFERENCES_PATHS.createMonthlyPreference,
                method: ApiMethods.POST,
                body: body
            }); }
        }),
        deleteMonthlyPreferences: build.mutation({
            query: function (id) { return ({
                url: "".concat(MONTHLY_PREFERENCES_PATHS.deletePreferences, "?id=").concat(id),
                method: ApiMethods.DELETE
            }); }
        }),
        getMonthlyPreferencesDeadline: build.query({
            query: function () { return MONTHLY_PREFERENCES_PATHS.monthlyPreferencesDeadline; }
        }),
        getUnavailableWeekend: build.mutation({
            query: function () { return "".concat(MONTHLY_PREFERENCES_PATHS.unavailableWeekend); }
        }),
        deleteUnavailableWeekend: build.mutation({
            query: function (id) { return ({
                url: "".concat(MONTHLY_PREFERENCES_PATHS.deletePreferences, "?id=").concat(id),
                method: ApiMethods.DELETE
            }); }
        }),
        getShiftPreferencesDeadline: build.query({
            query: function () { return MONTHLY_PREFERENCES_PATHS.shiftPreferencesDeadline; }
        }),
        getLeavePreferences: build.mutation({
            query: function () { return MONTHLY_PREFERENCES_PATHS.leavePreferences; }
        }),
        createLeavePreferences: build.mutation({
            query: function (body) { return ({
                url: MONTHLY_PREFERENCES_PATHS.leavePreferences,
                method: ApiMethods.PUT,
                body: body
            }); }
        }),
        getShiftPreferences: build.mutation({
            query: function () { return MONTHLY_PREFERENCES_PATHS.shiftPreferences; }
        }),
        createShiftPreferences: build.mutation({
            query: function (body) { return ({
                url: MONTHLY_PREFERENCES_PATHS.shiftPreferences,
                method: ApiMethods.PUT,
                body: body
            }); }
        })
    }); }
});
export var useGetMonthlyPreferencesMutation = preferencesApi.useGetMonthlyPreferencesMutation;
export default preferencesApi;
