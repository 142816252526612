var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Icon from '@components/Icon';
import Styled from './Tooltip.styled';
import { useState } from 'react';
var Tooltip = function (_a) {
    var info = _a.info;
    var _b = useState(false), showTooltip = _b[0], setShowToolTip = _b[1];
    var toggleTooltipHandler = function () {
        setShowToolTip(function (showTooltip) { return !showTooltip; });
    };
    var hideTooltipHandler = function () {
        setShowToolTip(false);
    };
    return (_jsxs(Styled.Container, __assign({ className: 'tooltip-container' }, { children: [_jsx("div", __assign({ onClick: toggleTooltipHandler }, { children: _jsx(Icon, { type: 'info', size: '18', color: '#4e5cff', customStyles: Styled.iconCss }) })), showTooltip && _jsxs(Styled.Tooltip, { children: [_jsx("span", { children: info }), _jsx(Styled.CloseBtn, __assign({ onClick: hideTooltipHandler }, { children: "Got it" }))] })] })));
};
export default Tooltip;
