var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var Center = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n"], ["\n    display: flex;\n    justify-content: center;\n"])));
var PlainButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    outline: none;\n    background: none;\n    border: none;\n    &:hover{\n        cursor: pointer;\n    }\n"], ["\n    outline: none;\n    background: none;\n    border: none;\n    &:hover{\n        cursor: pointer;\n    }\n"])));
var CenterButton = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    display: flex;\n    justify-content: center;\n    &:hover{\n        cursor: pointer;\n    }\n"], ["\n    display: flex;\n    justify-content: center;\n    &:hover{\n        cursor: pointer;\n    }\n"])));
var SpaceBetweenAlignCenter = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n"], ["\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n"])));
var TextButtonPrimary = styled.label(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n     font-size: 1.4rem;\n    font-weight: 500;\n    color: var(--primary-color);\n    &:hover{\n        cursor: pointer;\n    }\n    ", "\n"], ["\n     font-size: 1.4rem;\n    font-weight: 500;\n    color: var(--primary-color);\n    &:hover{\n        cursor: pointer;\n    }\n    ", "\n"])), function (_a) {
    var customeStyles = _a.customeStyles;
    return customeStyles;
});
var ErrorLabel = styled.p(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: red;\n    font-size: 10px;\n"], ["\n    color: red;\n    font-size: 10px;\n"])));
var Label = styled.label(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    margin-top: .5rem;\n    font-size: 1.5rem;\n    font-weight: 600;\n    color: #5c5b6e;\n"], ["\n    margin-top: .5rem;\n    font-size: 1.5rem;\n    font-weight: 600;\n    color: #5c5b6e;\n"])));
var ColumnGap1 = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n"], ["\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n"])));
export default {
    Center: Center,
    PlainButton: PlainButton,
    CenterButton: CenterButton,
    SpaceBetweenAlignCenter: SpaceBetweenAlignCenter,
    TextButtonPrimary: TextButtonPrimary,
    ErrorLabel: ErrorLabel,
    Label: Label,
    ColumnGap1: ColumnGap1
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
