var getIncludeInGenerationMessages = function (errCode) {
    var msg = '';
    switch (errCode) {
        default:
            msg = 'Oops Somthing went wrong. Please try again!';
    }
    return msg;
};
var getInitiateSurveyMessages = function (errCode) {
    var msg = '';
    switch (errCode) {
        default:
            msg = 'Oops Somthing went wrong. Please try again!';
    }
    return msg;
};
var getupdateMemberDetailsMessages = function (errCode) {
    var msg = '';
    switch (errCode) {
        default:
            msg = 'Oops Somthing went wrong. Please try again!';
    }
    return msg;
};
export default {
    getIncludeInGenerationMessages: getIncludeInGenerationMessages,
    getInitiateSurveyMessages: getInitiateSurveyMessages,
    getupdateMemberDetailsMessages: getupdateMemberDetailsMessages
};
