var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { hideToast } from '@store/features/app';
import { useAppDispatch } from '@store';
import Icon from '@components/Icon';
import Styled from './Toast.styled';
var Toast = function (props) {
    var dispatch = useAppDispatch();
    useEffect(function () {
        setTimeout(function () {
            dispatch(hideToast());
        }, 3000);
    }, []);
    if (!props.message) {
        return _jsx(_Fragment, {});
    }
    var onClose = function () {
        dispatch(hideToast());
    };
    return (_jsxs(Styled.Toast, __assign({ type: props.type }, { children: [_jsxs(Styled.ToastInnerDiv, { children: [props.type === 'Success'
                        ? _jsx(Icon, { type: 'successTick', color: '#40ad70' })
                        : _jsx(Icon, { type: 'caution' }), _jsxs(Styled.TextWrap, { children: [_jsxs(Styled.Text, __assign({ className: 'text-status', color: props.type === 'Success' ? '#0b6048' : '#600b0b' }, { children: [props.type, "!"] })), _jsx(Styled.Text, __assign({ className: 'text-message', color: props.type === 'Success' ? '#0b6048' : '#600b0b' }, { children: props.message }))] })] }), _jsx("div", __assign({ onClick: onClose }, { children: _jsx(Icon, { type: 'closeIcon', color: 'black', customStyles: Styled.CloseBtn }) }))] })));
};
export default Toast;
