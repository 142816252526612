var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import media from '@utils/media';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 9rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 24px;\n  color: #000;\n  user-select: none;\n"], ["\n  height: 9rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 24px;\n  color: #000;\n  user-select: none;\n"])));
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n"], ["\n  display: flex;\n"])));
var PageTitle = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 24px;\n  font-family: PoppinsSemiBold;\n  margin-right: 1.2rem;\n  ", "\n"], ["\n  font-size: 24px;\n  font-family: PoppinsSemiBold;\n  margin-right: 1.2rem;\n  ", "\n"])), media.lessThan('sm')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    max-width: 14rem;\n    font-size: 2rem;\n  "], ["\n    max-width: 14rem;\n    font-size: 2rem;\n  "]))));
export default {
    Container: Container,
    PageTitle: PageTitle,
    Wrapper: Wrapper
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
