var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  margin-right: 2rem;\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n  margin-right: 2rem;\n  ", "\n"])), function (_a) {
    var customStyles = _a.customStyles;
    return customStyles;
});
export default {
    Container: Container
};
var templateObject_1;
