var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
import DBIcon from '../../assets/icons/db_icon.svg';
import ProjectIcon from '@assets/icons/project_icon.svg';
import TeamIcon from '@assets/icons/team_icon.svg';
import ShiftIcon from '@assets/icons/shift_icon.svg';
import CaretDown from '@assets/icons/caret_down.svg';
import Timer from '@assets/icons/timer.svg';
import Clock from '@assets/icons/clock.svg';
import HourGlass from '@assets/icons/hourglass.svg';
import Calendar from '@assets/icons/calendar.svg';
// import media from '@utils/media';
import Remove from '@assets/icons/remove.svg';
import EditIcon from '@assets/icons/edit.svg';
import MailIcon from '@assets/icons/mail.svg';
import WhatsappIcon from '@assets/icons/whatsapp.svg';
import ShiftPreference from '@assets/icons/shift_preference.svg';
import LeaveIcon from '@assets/icons/leave_icon.svg';
import ArrowRight from '@assets/icons/arrow_right.svg';
import CautionIcon from '@assets/icons/caution.svg';
import DeleteIcon from '@assets/icons/delete.svg';
import AddIcon from '@assets/icons/addIcon.svg';
import ArrowLeft from '@assets/icons/arrow_left.svg';
import SearchIcon from '@assets/icons/searchIcon.svg';
import DecrementIcon from '@assets/icons/decrementIcon.svg';
import IncrementIcon from '@assets/icons/incrementIcon.svg';
import SuccessTick from '@assets/icons/successTick.svg';
import CloseIcon from '@assets/icons/closeIcon.svg';
import EllipsisIcon from '@assets/icons/ellipsisIcon.svg';
import NewWindowIcon from '@assets/icons/new_window.svg';
import CalendarInputFieldIcon from '@assets/icons/calendarInputField.svg';
import CaretLeft from '@assets/icons/caret_left.svg';
import CaretRight from '@assets/icons/caret_right.svg';
import CompletionTick from '@assets/icons/completion_tick.svg';
import Info from '@assets/icons/info.svg';
import TickGreenBg from '@assets/icons/tick_green_bg.svg';
import Close from '@assets/icons/close.svg';
import ColorPicker from '@assets/icons/color.svg';
export var IconMap = {
    dashboard: _jsx(DBIcon, {}),
    team: _jsx(TeamIcon, {}),
    project: _jsx(ProjectIcon, {}),
    shift: _jsx(ShiftIcon, {}),
    caret: _jsx(CaretDown, {}),
    timer: _jsx(Timer, {}),
    clock: _jsx(Clock, {}),
    hourglass: _jsx(HourGlass, {}),
    calendar: _jsx(Calendar, {}),
    remove: _jsx(Remove, {}),
    edit: _jsx(EditIcon, {}),
    mail: _jsx(MailIcon, {}),
    whatsapp: _jsx(WhatsappIcon, {}),
    shiftPreference: _jsx(ShiftPreference, {}),
    leaveIcon: _jsx(LeaveIcon, {}),
    arrowRight: _jsx(ArrowRight, {}),
    caution: _jsx(CautionIcon, {}),
    delete: _jsx(DeleteIcon, {}),
    addIcon: _jsx(AddIcon, {}),
    arrowLeft: _jsx(ArrowLeft, {}),
    decrementIcon: _jsx(DecrementIcon, {}),
    incrementIcon: _jsx(IncrementIcon, {}),
    successTick: _jsx(SuccessTick, {}),
    closeIcon: _jsx(CloseIcon, {}),
    search: _jsx(SearchIcon, {}),
    ellipsis: _jsx(EllipsisIcon, {}),
    newWindow: _jsx(NewWindowIcon, {}),
    calendarInputFieldIcon: _jsx(CalendarInputFieldIcon, {}),
    caretLeft: _jsx(CaretLeft, {}),
    caretRight: _jsx(CaretRight, {}),
    completionTick: _jsx(CompletionTick, {}),
    tickGreenBg: _jsx(TickGreenBg, {}),
    info: _jsx(Info, {}),
    close: _jsx(Close, {}),
    colorPicker: _jsx(ColorPicker, {})
};
var IconContainer = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  user-select: none;\n  ", "\n  ", "\n  ", "\n"], ["\n  display: flex;\n  user-select: none;\n  ", "\n  ", "\n  ", "\n"])), function (_a) {
    var color = _a.color;
    return color && "\n    svg {\n      g[stroke] {\n        stroke: ".concat(color, ";\n      }\n      path[fill] {\n        fill: ").concat(color, ";\n      }\n      path[stroke] {\n        stroke: ").concat(color, ";\n      }\n    }\n  ");
}, function (_a) {
    var size = _a.size;
    return Boolean(size) && "\n    width: ".concat(size, "px;\n    height: ").concat(size, "px;\n  ");
}, function (_a) {
    var customStyles = _a.customStyles;
    return "".concat(customStyles);
});
var Icon = function (props) {
    var _a, _b;
    return _jsx(IconContainer, __assign({ size: (_a = props.size) !== null && _a !== void 0 ? _a : 24, color: (_b = props.color) !== null && _b !== void 0 ? _b : '#6E6C7D', customStyles: props.customStyles, className: 'icon-container' }, { children: IconMap[props.type] }));
};
export default Icon;
var templateObject_1;
