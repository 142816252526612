import { ApiMethods } from '@enums/api.enum';
import { api } from './common.api';
import { MEMBER_UNAVAILABLE_PATHS } from '@constants/api.constants';
var memberUnavailableApi = api.injectEndpoints({
    endpoints: function (builder) { return ({
        getMemberUnavailableHistory: builder.query({
            query: function () { return MEMBER_UNAVAILABLE_PATHS.markUnavailability; },
            forceRefetch: function () { return true; }
        }),
        getShiftByDate: builder.query({
            query: function (date) { return "".concat(MEMBER_UNAVAILABLE_PATHS.shift, "?date=").concat(date); },
            forceRefetch: function () { return true; }
        }),
        postUnavailability: builder.mutation({
            query: function (body) { return ({
                url: MEMBER_UNAVAILABLE_PATHS.markUnavailability,
                method: ApiMethods.POST,
                body: JSON.stringify(body)
            }); }
        })
    }); }
});
export default memberUnavailableApi;
