export default {
    basicDet: 'Basic Shift Details',
    shiftnme: 'Shift name',
    shiftCode: 'Shift code',
    shiftCodePlaceholder: 'Type 3 letter code',
    shiftColor: 'Color',
    shiftColorPlaceholder: 'Choose color',
    shiftNamePlaceholder: 'Type shift name here',
    shiftStart: 'Start time',
    shiftEnd: 'End time',
    applicableDays: 'Applicable days',
    roleReq: 'Role requirements',
    roleName: 'Role name',
    saveShiftError: 'Unable to save the shift',
    deleteShiftError: 'Unable to delete the shift',
    disableShiftError: 'Unable to enable/disable the shift',
    saveShiftSuccess: 'Shift created successfully',
    saveShiftEditSuccess: 'Shift edited successfully',
    deleteShiftSuccess: 'Shift edited successfully',
    disableShiftSuccess: 'Shift enable/disable successfully',
    minRole: 'Min number',
    maxRole: 'Max number',
    disableToDelete: 'Disable shift first to delete!'
};
