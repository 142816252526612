import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AvatarMenu from './AvatarMenu';
import Styled from './Header.styled';
import { useMatches } from 'react-router-dom';
import { useMemo } from 'react';
import { findOccurenceValue } from '@utils';
import { useAppDispatch, useAppSelector } from '@store';
import { triggerLogout } from '@store/features/auth';
import Tooltip from '@components/InfoTooltip';
import { BackBtn } from '@core-components';
var Header = function (props) {
    var dispatch = useAppDispatch();
    var matches = useMatches();
    var title = useMemo(function () { return findOccurenceValue(matches, ['handle', 'meta', 'title']); }, [matches]);
    var _a = useAppSelector(function (state) { return state.app; }), headingTooltip = _a.headingTooltip, _b = _a.headerBackButton, hasBackButton = _b.hasBackButton, url = _b.url;
    return (_jsxs(Styled.Container, { children: [_jsxs(Styled.Wrapper, { children: [hasBackButton && _jsx(BackBtn, { url: url }), _jsx(Styled.PageTitle, { children: title !== null && title !== void 0 ? title : '' }), headingTooltip && _jsx(Tooltip, { info: headingTooltip })] }), _jsx(AvatarMenu, { logout: function () { void dispatch(triggerLogout()); }, role: 'member' })] }));
};
export default Header;
