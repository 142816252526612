import { api } from './common.api';
import { HELP_LINKS } from '@constants/api.constants';
var helpLinksApi = api.injectEndpoints({
    endpoints: function (builder) { return ({
        getHelpLinks: builder.query({
            query: function () { return HELP_LINKS.helpLinks; }
        })
    }); }
});
export var useGetHelpLinksQuery = helpLinksApi.useGetHelpLinksQuery;
export default helpLinksApi;
