var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import CommonStyled from '@containers/App/GlobalStyles/Common.styled';
import styled from 'styled-components';
var InputBox = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        height: 50px;\n        max-width:", ";\n        outline: none;\n        border-radius: 10px;\n        padding: 2rem;\n        border: none;\n        margin-left:2px;\n        outline: ", ";\n        :focus{\n        border: none;\n        outline: ", "\n        }\n        &::placeholder{\n            color: #6e6c7d;\n            font-size: 14px;\n        }\n    "], ["\n        height: 50px;\n        max-width:", ";\n        outline: none;\n        border-radius: 10px;\n        padding: 2rem;\n        border: none;\n        margin-left:2px;\n        outline: ", ";\n        :focus{\n        border: none;\n        outline: ", "\n        }\n        &::placeholder{\n            color: #6e6c7d;\n            font-size: 14px;\n        }\n    "])), function (props) { return props.width ? props.width : '400px'; }, function (props) { return (props === null || props === void 0 ? void 0 : props.error) !== '' ? '2px solid red;' : '2px solid #d8d8d8;'; }, function (props) { return (props === null || props === void 0 ? void 0 : props.error) !== '' ? '2px solid red;' : '2px solid var(--primary-color);'; });
var Label = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        margin-top: .5rem;\n        font: 1.5rem PoppinsSemiBold;\n        color: #5c5b6e;\n    "], ["\n        margin-top: .5rem;\n        font: 1.5rem PoppinsSemiBold;\n        color: #5c5b6e;\n    "])));
var Wrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        display: flex;\n        flex-direction: column;\n        gap: 1rem;\n    "], ["\n        display: flex;\n        flex-direction: column;\n        gap: 1rem;\n    "])));
var InputWithoutUseForm = function (props) {
    var _a, _b;
    return (_jsxs(Wrapper, { children: [props.label ? _jsx(Label, __assign({ htmlFor: props.name }, { children: props.label })) : _jsx(_Fragment, {}), _jsx(InputBox, __assign({ type: props.type, min: props === null || props === void 0 ? void 0 : props.min, max: props === null || props === void 0 ? void 0 : props.max, name: props.name, maxLength: props === null || props === void 0 ? void 0 : props.mask, placeholder: props.placeholder ? props.placeholder : '' }, props.register(props.name), { width: props === null || props === void 0 ? void 0 : props.width, error: (_a = props === null || props === void 0 ? void 0 : props.error) !== null && _a !== void 0 ? _a : '', disabled: (_b = props === null || props === void 0 ? void 0 : props.disabled) !== null && _b !== void 0 ? _b : false })), (props === null || props === void 0 ? void 0 : props.error) !== '' && _jsx(CommonStyled.ErrorLabel, { children: props === null || props === void 0 ? void 0 : props.error })] }));
};
export default InputWithoutUseForm;
var templateObject_1, templateObject_2, templateObject_3;
