import { api } from './common.api';
import { DAY_SCHEDULE } from '@constants/api.constants';
var dayScheduleApi = api.injectEndpoints({
    endpoints: function (builder) { return ({
        getDaySchedule: builder.mutation({
            query: function (day) { return "".concat(DAY_SCHEDULE.daySchedule, "?date=").concat(day); }
        })
    }); }
});
export var useGetDayScheduleMutation = dayScheduleApi.useGetDayScheduleMutation;
export default dayScheduleApi;
