var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import messages from '@containers/ProjectDetails/messages';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import projectDetailsApi from '@store/api/projectDetails.api';
var initialState = {
    isProjectDetailsLoading: false,
    isRolesLoading: false,
    isShiftsLoading: false,
    isHolidaysLoading: false,
    projectDetailsError: '',
    rolesError: '',
    shiftsError: '',
    holidaysError: '',
    projectDetails: {},
    roles: [],
    shifts: [],
    holidays: [],
    projectEditSuccess: false,
    toaster: { show: false, message: '', flag: false }
};
export var fetchProjectDetails = createAsyncThunk('projectDetails/fetchProjectDetails', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(projectDetailsApi.endpoints.getProjectDetails.initiate(_, {
                    subscribe: false
                }))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var fetchRoles = createAsyncThunk('projectDetails/fetchRoles', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(projectDetailsApi.endpoints.getRoles.initiate(_, { subscribe: false }))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var fetchShifts = createAsyncThunk('projectDetails/fetchShifts', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(projectDetailsApi.endpoints.getShifts.initiate(_, { subscribe: false }))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var fetchHolidays = createAsyncThunk('projectDetails/fetchHolidays', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(projectDetailsApi.endpoints.getHolidays.initiate(_, { subscribe: false }))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var editProjectDetails = createAsyncThunk('projectDetails/editProjectDetails', function (body, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(projectDetailsApi.endpoints.editProject.initiate(body))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
var projectDetailsSlice = createSlice({
    name: 'project-deatails',
    initialState: initialState,
    reducers: {
        hideToaster: function (state) {
            state.toaster.show = false;
            state.toaster.message = '';
        },
        resetSuccess: function (state) {
            state.projectEditSuccess = false;
        }
    },
    extraReducers: function (builder) {
        builder
            .addMatcher(projectDetailsApi.endpoints.getProjectDetails.matchPending, function (state) {
            state.isProjectDetailsLoading = true;
        })
            .addMatcher(projectDetailsApi.endpoints.getProjectDetails.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.isProjectDetailsLoading = false;
            state.projectDetailsError = payload === null || payload === void 0 ? void 0 : payload.data;
        })
            .addMatcher(projectDetailsApi.endpoints.getProjectDetails.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isProjectDetailsLoading = false;
            state.projectDetailsError = '';
            state.projectDetails = payload === null || payload === void 0 ? void 0 : payload.result;
        });
        builder
            .addMatcher(projectDetailsApi.endpoints.editProject.matchPending, function (state) {
            state.isProjectDetailsLoading = true;
        })
            .addMatcher(projectDetailsApi.endpoints.editProject.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.isProjectDetailsLoading = false;
            state.toaster.message = messages.projectEditFailed;
            state.toaster.flag = false;
            state.toaster.show = true;
        })
            .addMatcher(projectDetailsApi.endpoints.editProject.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isProjectDetailsLoading = false;
            state.toaster.flag = true;
            state.toaster.show = true;
            state.toaster.message = messages.projectEditSuccess;
            state.projectEditSuccess = true;
        });
        builder
            .addMatcher(projectDetailsApi.endpoints.getRoles.matchPending, function (state) {
            state.isRolesLoading = true;
        })
            .addMatcher(projectDetailsApi.endpoints.getRoles.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.isRolesLoading = false;
            state.rolesError = payload === null || payload === void 0 ? void 0 : payload.data;
        })
            .addMatcher(projectDetailsApi.endpoints.getRoles.matchFulfilled, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.isRolesLoading = false;
            state.rolesError = '';
            state.roles = (_b = payload === null || payload === void 0 ? void 0 : payload.result) === null || _b === void 0 ? void 0 : _b.roles;
        });
        builder
            .addMatcher(projectDetailsApi.endpoints.getShifts.matchPending, function (state) {
            state.isShiftsLoading = true;
        })
            .addMatcher(projectDetailsApi.endpoints.getShifts.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.isShiftsLoading = false;
            state.shiftsError = payload === null || payload === void 0 ? void 0 : payload.data;
            state.shifts = [];
        })
            .addMatcher(projectDetailsApi.endpoints.getShifts.matchFulfilled, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.isShiftsLoading = false;
            state.shiftsError = '';
            state.shifts = (_b = payload === null || payload === void 0 ? void 0 : payload.result) === null || _b === void 0 ? void 0 : _b.shifts;
        });
        builder
            .addMatcher(projectDetailsApi.endpoints.getHolidays.matchPending, function (state) {
            state.isHolidaysLoading = true;
        })
            .addMatcher(projectDetailsApi.endpoints.getHolidays.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.isHolidaysLoading = false;
            state.projectDetailsError = payload === null || payload === void 0 ? void 0 : payload.data;
        })
            .addMatcher(projectDetailsApi.endpoints.getHolidays.matchFulfilled, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.isHolidaysLoading = false;
            state.holidaysError = '';
            state.holidays = (_b = payload === null || payload === void 0 ? void 0 : payload.result) === null || _b === void 0 ? void 0 : _b.holidays;
        });
    }
});
export var hideToaster = (_a = projectDetailsSlice.actions, _a.hideToaster), resetSuccess = _a.resetSuccess;
export default projectDetailsSlice.reducer;
