var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
var Table = styled.table(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  &.fixedColumns {\n    width: max-content;\n  }\n  &.edit {\n    width: fit-content;\n  }\n  position: relative;\n  border-bottom: 1px solid #f4f4f4;\n  font-family: PoppinsRegular;\n  font-size: 1.4rem;\n   :first-child{\n    box-shadow: 2px 0 15px -8px #888;\n    clip-path: inset(0px -15px 0px 0px);\n  }\n  :last-child{\n    box-shadow: 2px 0 18px -8px #888;\n    clip-path: inset(0px 0px 0px -15px);\n  }\n  ", ";\n"], ["\n  width: 100%;\n  height: 100%;\n  &.fixedColumns {\n    width: max-content;\n  }\n  &.edit {\n    width: fit-content;\n  }\n  position: relative;\n  border-bottom: 1px solid #f4f4f4;\n  font-family: PoppinsRegular;\n  font-size: 1.4rem;\n   :first-child{\n    box-shadow: 2px 0 15px -8px #888;\n    clip-path: inset(0px -15px 0px 0px);\n  }\n  :last-child{\n    box-shadow: 2px 0 18px -8px #888;\n    clip-path: inset(0px 0px 0px -15px);\n  }\n  ", ";\n"])), function (_a) {
    var customStyles = _a.customStyles;
    return customStyles;
});
var Thead = styled.thead(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  z-index: 1;\n  font-family: PoppinsSemiBold;\n  font-weight: 400;\n  ", ";\n"], ["\n  z-index: 1;\n  font-family: PoppinsSemiBold;\n  font-weight: 400;\n  ", ";\n"])), function (_a) {
    var customStyles = _a.customStyles;
    return customStyles;
});
var Tbody = styled.tbody(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), function (_a) {
    var customStyles = _a.customStyles;
    return customStyles;
});
var TableRow = styled.tr(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  padding-left: 15px;\n  &.bottom{\n    .tooltip {\n     top: -350%;\n    }\n  }\n  ", ";\n"], ["\n  width: 100%;\n  padding-left: 15px;\n  &.bottom{\n    .tooltip {\n     top: -350%;\n    }\n  }\n  ", ";\n"])), function (_a) {
    var customStyles = _a.customStyles;
    return customStyles;
});
var TCell = styled.td(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  :last-child {\n    border-right: none;\n  }\n  border-right: 1px solid #f4f4f4;\n  height: 5.9rem;\n  max-height: 5.9rem;\n  padding: 0 1.5rem;\n  &.shadow-right {\n    box-shadow: rgba(0, 0, 0, 0.12) -4px 0px 11px 2px;\n    clip-path: inset(0px -15px 0px 0px);\n  }\n  &.shadow-left {\n    box-shadow: -4px 0px 11px 2px rgba(156, 156, 156, 0.12);\n    clip-path: inset(0px 0px 0px -15px);\n  }\n  ", ";\n  &.last{\n    .tooltip {\n      left: auto;\n      right: 7.5rem;\n    }\n  }\n"], ["\n  :last-child {\n    border-right: none;\n  }\n  border-right: 1px solid #f4f4f4;\n  height: 5.9rem;\n  max-height: 5.9rem;\n  padding: 0 1.5rem;\n  &.shadow-right {\n    box-shadow: rgba(0, 0, 0, 0.12) -4px 0px 11px 2px;\n    clip-path: inset(0px -15px 0px 0px);\n  }\n  &.shadow-left {\n    box-shadow: -4px 0px 11px 2px rgba(156, 156, 156, 0.12);\n    clip-path: inset(0px 0px 0px -15px);\n  }\n  ", ";\n  &.last{\n    .tooltip {\n      left: auto;\n      right: 7.5rem;\n    }\n  }\n"])), function (_a) {
    var customStyles = _a.customStyles;
    return customStyles;
});
var Cell = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  position: relative;\n"], ["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  position: relative;\n"])));
var TableCell = function (props) { return (_jsx(TCell, __assign({ customStyles: props.customStyles, className: props.className }, { children: _jsx(Cell, { children: props.children }) }))); };
export default {
    Table: Table,
    Thead: Thead,
    Tbody: Tbody,
    TableRow: TableRow,
    TableCell: TableCell
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
