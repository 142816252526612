var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
var Checkbox = function (props) {
    var Label = styled.label(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        margin-top: .5rem;\n        font: 1.5rem PoppinsSemiBold;\n        color: #5c5b6e;\n    "], ["\n        margin-top: .5rem;\n        font: 1.5rem PoppinsSemiBold;\n        color: #5c5b6e;\n    "])));
    var CheckBoxWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        display: flex;\n        justify-content: space-between;\n        max-width: 400px;\n    "], ["\n        display: flex;\n        justify-content: space-between;\n        max-width: 400px;\n    "])));
    var CheckBoxTitle = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        font-size: 13px;\n        ", "\n"], ["\n        font-size: 13px;\n        ", "\n"])), function (_a) {
        var checked = _a.checked;
        return checked && "\n            color:#4e5cff;\n        ";
    });
    var CheckBox = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        display: flex;\n        align-items: center;\n        gap: .5rem;\n        cursor: pointer;\n        input:checked{\n            accent-color: #4e5cff;\n        }\n        input{\n            cursor: pointer;\n        }\n    "], ["\n        display: flex;\n        align-items: center;\n        gap: .5rem;\n        cursor: pointer;\n        input:checked{\n            accent-color: #4e5cff;\n        }\n        input{\n            cursor: pointer;\n        }\n    "])));
    var handleChange = function (option) {
        if (props.checked.includes(option)) {
            var removeElement = props.checked.filter(function (item) { return item !== option; });
            props.setChecked(removeElement);
        }
        else {
            props.setChecked(__spreadArray(__spreadArray([], props.checked, true), [option], false));
        }
    };
    return (_jsxs(_Fragment, { children: [props.label ? _jsx(Label, __assign({ htmlFor: props.name }, { children: props.label })) : _jsx(_Fragment, {}), _jsx(CheckBoxWrapper, { children: (props.options.map(function (option) {
                    return _jsxs(CheckBox, __assign({ onClick: function () { handleChange(option); } }, { children: [_jsx("label", __assign({ style: { display: 'flex' } }, { children: _jsx("input", { type: "checkbox", name: props.name, checked: props.checked.includes(option), onChange: function () { handleChange(option); } }) })), _jsx(CheckBoxTitle, __assign({ checked: props.checked.includes(option) }, { children: option }))] }), option);
                })) })] }));
};
export default Checkbox;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
