var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/indent */
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CaretDown from '@assets/icons/caret_down.webp';
import CommonStyled from '@containers/App/GlobalStyles/Common.styled';
import _ from 'lodash';
var DropdownWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        position: relative;\n        font-size: 13px;\n        display: flex;\n        flex-direction: column;\n        min-width:100%;\n        gap: 1rem;\n    "], ["\n        position: relative;\n        font-size: 13px;\n        display: flex;\n        flex-direction: column;\n        min-width:100%;\n        gap: 1rem;\n    "])));
var DropdownToggle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        width: 100%;\n        height: 50px;\n        min-width: 8rem;\n        border-radius: 10px;\n        align-items: center;\n        display: flex;\n        border: ", ";\n        padding: 1rem;\n        cursor: pointer;\n    "], ["\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        width: 100%;\n        height: 50px;\n        min-width: 8rem;\n        border-radius: 10px;\n        align-items: center;\n        display: flex;\n        border: ", ";\n        padding: 1rem;\n        cursor: pointer;\n    "])), function (_a) {
    var isError = _a.isError;
    return isError === true ? '2px solid red;' : '2px solid #d8d8d8;';
});
var Caret = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        width: 24px;\n        height: 24px;\n        background: url(", ") no-repeat;\n        background-size: contain;\n        margin-left: 12px;\n        transition: 0.3s;\n        ", "\n    "], ["\n        width: 24px;\n        height: 24px;\n        background: url(", ") no-repeat;\n        background-size: contain;\n        margin-left: 12px;\n        transition: 0.3s;\n        ", "\n    "])), CaretDown, function (_a) {
    var isOpen = _a.isOpen;
    return isOpen && "\n            transform: rotate(180deg);\n        ";
});
var DropdownOptionWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        position: absolute;\n        top: 6rem;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        gap: .5rem;\n        border: 1px solid #d8d8d8;\n        border-radius: 10px;\n        width: 100%;\n        overflow: hidden;\n        z-index: 10;\n        background: #fff;\n    "], ["\n        position: absolute;\n        top: 6rem;\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        gap: .5rem;\n        border: 1px solid #d8d8d8;\n        border-radius: 10px;\n        width: 100%;\n        overflow: hidden;\n        z-index: 10;\n        background: #fff;\n    "])));
var DropdownOption = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        padding:7px 3rem;\n        position: relative;\n        cursor: pointer;\n        width: 100%;\n        text-align: center;\n        &:hover{\n            background: #f1f0f0;\n        }\n        ", "\n    "], ["\n        padding:7px 3rem;\n        position: relative;\n        cursor: pointer;\n        width: 100%;\n        text-align: center;\n        &:hover{\n            background: #f1f0f0;\n        }\n        ", "\n    "])), function (_a) {
    var isSelected = _a.isSelected;
    return isSelected && "\n            background: #f8f8f8;\n            color: #4e5cff;\n        ";
});
var Placeholder = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        font-size: 14px;\n        color: #6e6c7d;\n    "], ["\n        font-size: 14px;\n        color: #6e6c7d;\n    "])));
var Select = function (props) {
    var _a;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var dropDownRef = useRef(null);
    var toggleDropdown = function () {
        setIsOpen(!isOpen);
    };
    var handleOptionClick = function (option) {
        props.onSelect(option);
        setIsOpen(false);
    };
    var renderSelectedOption = function (selectedOption) {
        var option = '';
        if (_.isString(selectedOption)) {
            option = selectedOption;
        }
        else if ('shiftName' in selectedOption) {
            option = "".concat(selectedOption.date, " ").concat(selectedOption.shiftName);
        }
        else {
            option = selectedOption.name;
        }
        return _jsx("span", { children: option });
    };
    useEffect(function () {
        var current = dropDownRef.current;
        var handleOutsideClick = function (event) {
            if (current && !(current === null || current === void 0 ? void 0 : current.contains(event.target))) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return function () {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);
    return (_jsxs(DropdownWrapper, { children: [_jsxs(DropdownToggle, __assign({ onClick: toggleDropdown, isError: !!(props === null || props === void 0 ? void 0 : props.error), ref: dropDownRef }, { children: [(props === null || props === void 0 ? void 0 : props.selectedOption)
                        ? renderSelectedOption(props.selectedOption)
                        : _jsx(Placeholder, { children: (_a = props === null || props === void 0 ? void 0 : props.placeHolder) !== null && _a !== void 0 ? _a : '' }), _jsx(Caret, { isOpen: isOpen })] })), isOpen && (_jsx(DropdownOptionWrapper, { children: props.options.map(function (option) {
                    var currentValue = '';
                    if (_.isString(option)) {
                        currentValue = option;
                    }
                    else if ('shiftName' in option) {
                        currentValue = "".concat(option.date, " ").concat(option.shiftName);
                    }
                    else {
                        currentValue = option.name;
                    }
                    var currentKey = '';
                    if (_.isString(option)) {
                        currentKey = option;
                    }
                    else if ('shiftName' in option) {
                        currentKey = "".concat(option.date, " ").concat(option.shiftId);
                    }
                    else {
                        currentKey = option.id;
                    }
                    return (_jsx(DropdownOption, __assign({ isSelected: props.selectedOption === currentValue, onClick: function () { handleOptionClick(option); } }, { children: currentValue }), currentKey));
                }) })), (props === null || props === void 0 ? void 0 : props.error) && _jsx(CommonStyled.ErrorLabel, { children: props === null || props === void 0 ? void 0 : props.error })] }));
};
export default Select;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
