import { ApiMethods } from '@enums/api.enum';
import { api } from './common.api';
import { PROJECT_DETAILS_PATH } from '@constants/api.constants';
var projectDetailsApi = api.injectEndpoints({
    endpoints: function (builder) { return ({
        getProjectDetails: builder.query({
            query: function () { return PROJECT_DETAILS_PATH.project; }
        }),
        editProject: builder.mutation({
            query: function (body) { return ({
                url: "".concat(PROJECT_DETAILS_PATH.project),
                method: ApiMethods.PUT,
                body: body
            }); }
        }),
        getRoles: builder.query({
            query: function () { return PROJECT_DETAILS_PATH.roles; }
        }),
        getShifts: builder.query({
            query: function () { return PROJECT_DETAILS_PATH.shifts; }
        }),
        getHolidays: builder.query({
            query: function () { return PROJECT_DETAILS_PATH.holidays; }
        })
    }); }
});
export default projectDetailsApi;
