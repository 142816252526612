var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import teamDetailsAdminApi from '@store/api/teamDetail.api';
import messages from './messages';
export var fetchTeamDetailsAdmin = createAsyncThunk('teamDetailsAdmin/fetchTeamDetails', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(teamDetailsAdminApi.endpoints.getMembers.initiate())];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var updateMembertDetails = createAsyncThunk('teamDetailsAdmin/updateMembertDetails', function (body, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(teamDetailsAdminApi.endpoints.putMemberDetails.initiate(body))];
            case 1:
                void (_a.sent());
                return [4 /*yield*/, thunkApi.dispatch(teamDetailsAdminApi.endpoints.getMembers.initiate())];
            case 2:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var initiateSurvey = createAsyncThunk('teamDetailsAdmin/initiateSurvey', function (body, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(teamDetailsAdminApi.endpoints.initiateSurvey.initiate(body))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var includeInGeneration = createAsyncThunk('teamDetailsAdmin/includeInGeneration', function (body, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(teamDetailsAdminApi.endpoints.includeInGeneration.initiate(body))];
            case 1:
                void (_a.sent());
                return [4 /*yield*/, thunkApi.dispatch(teamDetailsAdminApi.endpoints.getMembers.initiate())];
            case 2:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
var initialState = {
    isMembersLoading: false,
    teamDetails: [],
    teamDetailsError: {},
    isUpdateMemberDetailsLoading: false,
    updateMembertDetailsError: { message: '' },
    isInitiateSurveyLoading: false,
    initiateSurveyError: { message: '' },
    isIncludeInGenerationLoading: false,
    includeInGenerationError: { message: '' }
};
export var teamDetailsAdminSlice = createSlice({
    name: 'team-details-admin',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addMatcher(teamDetailsAdminApi.endpoints.getMembers.matchPending, function (state) {
            state.isMembersLoading = true;
        })
            .addMatcher(teamDetailsAdminApi.endpoints.getMembers.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.isMembersLoading = false;
            state.teamDetails = [];
            state.teamDetailsError = payload === null || payload === void 0 ? void 0 : payload.data;
        })
            .addMatcher(teamDetailsAdminApi.endpoints.getMembers.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isMembersLoading = false;
            state.teamDetailsError = {};
            state.teamDetails = payload === null || payload === void 0 ? void 0 : payload.result;
        });
        builder.addMatcher(teamDetailsAdminApi.endpoints.putMemberDetails.matchPending, function (state) {
            state.isUpdateMemberDetailsLoading = true;
        })
            .addMatcher(teamDetailsAdminApi.endpoints.putMemberDetails.matchRejected, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.isUpdateMemberDetailsLoading = false;
            state.updateMembertDetailsError.message = messages.getupdateMemberDetailsMessages((_b = payload === null || payload === void 0 ? void 0 : payload.data) === null || _b === void 0 ? void 0 : _b.status_code);
        })
            .addMatcher(teamDetailsAdminApi.endpoints.putMemberDetails.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isUpdateMemberDetailsLoading = false;
            state.updateMembertDetailsError.message = '';
        });
        builder.addMatcher(teamDetailsAdminApi.endpoints.initiateSurvey.matchPending, function (state) {
            state.isInitiateSurveyLoading = true;
        })
            .addMatcher(teamDetailsAdminApi.endpoints.initiateSurvey.matchRejected, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.isInitiateSurveyLoading = false;
            state.initiateSurveyError.message = messages.getInitiateSurveyMessages((_b = payload === null || payload === void 0 ? void 0 : payload.data) === null || _b === void 0 ? void 0 : _b.status_code);
        })
            .addMatcher(teamDetailsAdminApi.endpoints.initiateSurvey.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isInitiateSurveyLoading = false;
            state.initiateSurveyError.message = '';
        });
        builder.addMatcher(teamDetailsAdminApi.endpoints.includeInGeneration.matchPending, function (state) {
            state.isIncludeInGenerationLoading = true;
        })
            .addMatcher(teamDetailsAdminApi.endpoints.includeInGeneration.matchRejected, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.isIncludeInGenerationLoading = false;
            state.includeInGenerationError.message = messages.getIncludeInGenerationMessages((_b = payload === null || payload === void 0 ? void 0 : payload.data) === null || _b === void 0 ? void 0 : _b.status_code);
        })
            .addMatcher(teamDetailsAdminApi.endpoints.includeInGeneration.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.isIncludeInGenerationLoading = false;
            state.includeInGenerationError.message = '';
        });
    }
});
export default teamDetailsAdminSlice.reducer;
