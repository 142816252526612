var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import Styled from './BackBtn.styled';
import Icon from '@components/Icon';
var BackBtn = function (_a) {
    var color = _a.color, url = _a.url, customStyles = _a.customStyles;
    var navigate = useNavigate();
    var navigateHandler = function () {
        if (url) {
            navigate(url);
        }
        else {
            navigate(-1);
        }
    };
    return (_jsx(Styled.Container, __assign({ customStyles: customStyles, onClick: navigateHandler }, { children: _jsx(Icon, { type: 'arrowLeft', color: color !== null && color !== void 0 ? color : '#000' }) })));
};
export default BackBtn;
