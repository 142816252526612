var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
// API Reducer
import { api } from './api/common.api';
// Slice Reducers
import auth from './features/auth';
import user from './features/user';
import preferences from './features/preferences';
import memberUnavailable from './features/memberUnavailable';
import projectDetails from './features/projectDetails';
import monthlySchedule from './features/monthlySchedule';
import daySchedule from './features/daySchedule';
import helpLinks from './features/helpLinks';
import shiftGeneration from './features/shiftGeneration';
import app from './features/app';
import ShiftDetails from './features/ShiftDetails';
import teamDetailsAdmin from './features/TeamDetailsAdmin';
var createStore = function (options) {
    var _a;
    return configureStore(__assign({ reducer: (_a = {},
            _a[api.reducerPath] = api.reducer,
            _a.auth = auth,
            _a.user = user,
            _a.preferences = preferences,
            _a.memberUnavailable = memberUnavailable,
            _a.projectDetails = projectDetails,
            _a.monthlySchedule = monthlySchedule,
            _a.daySchedule = daySchedule,
            _a.helpLinks = helpLinks,
            _a.app = app,
            _a.shiftGeneration = shiftGeneration,
            _a.teamDetailsAdmin = teamDetailsAdmin,
            _a.ShiftDetails = ShiftDetails,
            _a), middleware: function (gDM) { return gDM().concat(api.middleware); } }, options));
};
/** Enhanced Store Reducer */
export var store = createStore();
export var useAppSelector = useSelector;
// export type AppDispatch = typeof store.dispatch;
export var useAppDispatch = useDispatch;
export default store;
