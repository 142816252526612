var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
var breakpoints = {
    xs: '0',
    ssm: '373px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
    mobile: '320px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px'
};
var getBreakpointValue = function (breakpoint) {
    return breakpoints[breakpoint] !== undefined ? breakpoints[breakpoint] : breakpoint;
};
/**
 * @param breakpoint
 * @returns CSS Object
 * @description Applies styles for screen sizes less than breakpoint
 */
var lessThan = function (breakpoint) { return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  @media (max-width: ", ") {\n    ", "\n  }\n"], ["\n  @media (max-width: ", ") {\n    ", "\n  }\n"])), getBreakpointValue(breakpoint), css(args));
}; };
/**
 * @param breakpoint
 * @returns CSS Object
 * @description Applies styles for screen sizes larger than breakpoint
 */
var greaterThan = function (breakpoint) { return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  @media (min-width: ", ") {\n    ", "\n  }\n"], ["\n  @media (min-width: ", ") {\n    ", "\n  }\n"])), getBreakpointValue(breakpoint), css(args));
}; };
/**
 *
 * @param from
 * @param to
 * @returns CSS Object
 * @description Applies styles for screen sizes in the range of from and to breakpoints
*/
var between = function (from, to) { return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  @media (min-width: ", ") and\n    (max-width: ", ") {\n      ", "\n    }\n    "], ["\n  @media (min-width: ", ") and\n    (max-width: ", ") {\n      ", "\n    }\n    "])), getBreakpointValue(from), getBreakpointValue(to), css(args));
}; };
/**
 *
 * @param device
 * @returns CSS Object
 * @description Applies styles for specific device screen sizes
 */
var forDevice = function (device) { return function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    if (device === 'mobile') {
        return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      @media (max-width: ", ") {\n        ", "    \n      }\n    "], ["\n      @media (max-width: ", ") {\n        ", "    \n      }\n    "])), getBreakpointValue('tablet'), css(args));
    }
    else if (device === 'tablet') {
        return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      @media (min-width: ", ") and (max-width: ", ") {\n        ", "\n      }\n    "], ["\n      @media (min-width: ", ") and (max-width: ", ") {\n        ", "\n      }\n    "])), getBreakpointValue('tablet'), getBreakpointValue('laptop'), css(args));
    }
    else if (device === 'laptop') {
        return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      @media (min-width: ", ") and (max-width: ", ") {\n        ", "\n      }\n    "], ["\n      @media (min-width: ", ") and (max-width: ", ") {\n        ", "\n      }\n    "])), getBreakpointValue('laptop'), getBreakpointValue('desktop'), css(args));
    }
    else if (device === 'desktop') {
        return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      @media (min-width: ", ") {\n        ", "\n      }\n    "], ["\n      @media (min-width: ", ") {\n        ", "\n      }\n    "])), getBreakpointValue('desktop'), css(args));
    }
}; };
export default {
    lessThan: lessThan,
    greaterThan: greaterThan,
    between: between,
    forDevice: forDevice
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
