/* eslint-disable @typescript-eslint/indent */
import { Role } from '@enums/role.enum';
/**
 * @description Routes
 */
var routes = {
    ROOT: {
        id: 'ROOT',
        path: '/',
        label: 'ROOT',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    HOME: {
        id: 'HOME',
        path: '/',
        label: 'Home',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    LOGIN: {
        id: 'LOGIN',
        path: '/login',
        label: 'Login',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    /* Dashboard Routes */
    DASHBOARD: {
        id: 'DASHBOARD',
        path: '/dashboard',
        label: 'Dashboard',
        title: 'Dashboard',
        icon: 'dashboard',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    DASHBOARD_TODAY: {
        id: 'DASHBOARD_TODAY',
        path: 'today',
        label: 'Today',
        permissions: {
            read: [Role.USER, Role.ADMIN, Role.MEMBER],
            write: []
        }
    },
    SHIFT_DETAILED: {
        id: 'SHIFT_DETAILED',
        path: 'shift-detailed',
        label: 'Shift Detailed'
    },
    HOW_TO_LINK: {
        id: 'HOW_TO_LINK',
        path: 'how-to',
        label: 'How To Links'
    },
    /* Monthly Routes */
    DASHBOARD_MONTHLY: {
        id: 'DASHBOARD_MONTHLY',
        path: 'monthly',
        label: 'Monthly',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    DAY_SHIFT_DETAILS: {
        id: 'DAY_SHIFT_DETAILS',
        path: 'day-shift-details',
        label: 'Shift Details'
    },
    MONTHLY_SHIFT_DETAILS: {
        id: 'MONTHLY_SHIFT_DETAILS',
        path: 'detailed-shift',
        label: 'Monthly Shift Details'
    },
    DASHBOARD_GENERATE_SHIFT: {
        id: 'DASHBOARD_GENERATE_SHIFT',
        path: 'generate-shift',
        label: 'Generate Shift',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    GENERATE_SHIFT_FLOW_STEP_1: {
        id: 'GENERATE_SHIFT_FLOW_STEP_1',
        path: 'initiate-survey',
        label: 'Initiate Survey',
        permissions: {
            read: [Role.ADMIN],
            write: [Role.ADMIN]
        }
    },
    GENERATE_SHIFT_FLOW_STEP_2: {
        id: 'GENERATE_SHIFT_FLOW_STEP_2',
        path: 'review-requests',
        label: 'Review Requests',
        permissions: {
            read: [Role.ADMIN],
            write: [Role.ADMIN]
        }
    },
    GENERATE_SHIFT_FLOW_STEP_3: {
        id: 'GENERATE_SHIFT_FLOW_STEP_3',
        path: 'review-shift-plan',
        label: 'Review Shift Plan',
        permissions: {
            read: [Role.ADMIN],
            write: [Role.ADMIN]
        }
    },
    GENERATE_SHIFT_FLOW_STEP_4: {
        id: 'GENERATE_SHIFT_FLOW_STEP_4',
        path: 'employee-shifts',
        label: 'Employee Shifts',
        permissions: {
            read: [Role.ADMIN],
            write: [Role.ADMIN]
        }
    },
    GENERATE_SHIFT_FLOW_STEP_5: {
        id: 'GENERATE_SHIFT_FLOW_STEP_5',
        path: 'admin-reports',
        label: 'Admin Reports',
        permissions: {
            read: [Role.ADMIN],
            write: [Role.ADMIN]
        }
    },
    DASHBOARD_MARK_UNAVAILABILITY: {
        id: 'DASHBOARD_MARK_UNAVAILABILITY',
        path: 'member-unavailable',
        label: 'Mark Unavailability'
    },
    MARK_UNAVAILABILITY: {
        id: 'MARK_UNAVAILABILITY',
        path: 'mark-unavailability',
        label: 'Mark Unavailability',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    MEMBER_UNAVAILABLE_HISTORY: {
        id: 'MEMBER_UNAVAILABLE_HISTORY',
        path: 'history',
        label: 'Member Unavailable History'
    },
    DASHBOARD_REPORTS: {
        id: 'DASHBOARD_REPORTS',
        path: 'reports',
        label: 'Reports',
        permissions: {
            read: [Role.ADMIN],
            write: []
        }
    },
    DASHBOARD_EMPLOYEE_SHIFT_SUMMARY: {
        id: 'DASHBOARD_EMPLOYEE_SHIFT_SUMMARY',
        path: 'employee-shift-summary',
        label: 'Employee Shift Summary',
        permissions: {
            read: [Role.ADMIN],
            write: []
        }
    },
    /* Project Details Route */
    DASHBOARD_MONTLY_PREFERENCES: {
        id: 'DASHBOARD_MONTLY_PREFERENCES',
        path: 'monthly-preferences',
        label: 'Monthly Preferences',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    LEAVES: {
        id: 'LEAVES',
        path: 'leaves',
        label: 'Leaves',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    UNAVAILABLE_WEEKENDS: {
        id: 'UNAVAILABLE_WEEKENDS',
        path: 'unavailable-weekends',
        label: 'Unavailable Weekends',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    ADD_NEW_LEAVE: {
        id: 'ADD_NEW_LEAVE',
        path: 'leaves/new',
        label: 'Add New Leave',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    ADD_NEW_UNAVAILABLE_WEEKENDS: {
        id: 'ADD_NEW_UNAVAILABLE_WEEKENDS',
        path: 'unavailable-weekends/new',
        label: 'Add New Leave',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    FAQ: {
        id: 'FAQ',
        path: 'faq',
        label: 'FAQ',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    PROJECT_DETAILS: {
        id: 'PROJECT_DETAILS',
        path: '/project-details',
        label: 'Project Details',
        title: 'Project Details',
        icon: 'project',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    PROJECT_DETAILS_ADD: {
        id: 'PROJECT_DETAILS_ADD',
        path: 'edit',
        label: 'Project Details Edit',
        title: 'Project Details Edit',
        icon: 'project',
        permissions: {
            read: [Role.ADMIN],
            write: []
        }
    },
    SHIFT_DETAILS: {
        id: 'SHIFT_DETAILS',
        path: '/shift-details',
        label: 'Shift Details',
        title: 'Shift Details',
        icon: 'shift',
        permissions: {
            read: [Role.ADMIN],
            write: []
        }
    },
    SHIFT_DETAILS_LANDING: {
        id: 'SHIFT_DETAILS_LANDING',
        path: '/shift-details/landing',
        label: 'Shift Details',
        title: 'Shift Details',
        icon: 'shift',
        permissions: {
            read: [Role.ADMIN],
            write: []
        }
    },
    SHIFT_DETAILS_ADD: {
        id: 'SHIFT_DETAILS_ADD',
        path: '/shift-details/shifts/edit',
        label: 'Shifts',
        permissions: {
            read: [Role.ADMIN],
            write: []
        }
    },
    SHIFT_DETAILS_VIEW: {
        id: 'SHIFT_DETAILS_VIEW',
        path: '/shift-details/shifts/view',
        label: 'Shifts',
        permissions: {
            read: [Role.ADMIN],
            write: []
        }
    },
    SHIFT_DETAILS_CONFIG: {
        id: 'SHIFT_DETAILS_CONFIG',
        path: 'shift-details-config',
        label: 'General Configuration',
        icon: 'shift',
        permissions: {
            read: [Role.ADMIN],
            write: []
        }
    },
    TEAM_MEMBERS: {
        id: 'TEAM_MEMBERS',
        path: '/team-members',
        label: 'Team Members',
        title: 'Team Members',
        icon: 'team',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    /* My Profile */
    MY_PROFILE: {
        id: 'MY_PROFILE',
        path: '/my-profile',
        label: 'My Profile',
        title: 'My Profile',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    GENERAL_LEAVE_PREFERENCES: {
        id: 'GENERAL_LEAVE_PREFERENCES',
        path: '/general-leave-preferences',
        label: 'General Leave Preferences',
        title: 'General Leave Preferences',
        permissions: {
            read: [Role.USER],
            write: []
        }
    },
    GENERAL_SHIFT_PREFERENCES: {
        id: 'GENERAL_SHIFT_PREFERENCES',
        path: '/general-shift-preferences',
        label: 'General Shift Preferences',
        title: 'General Shift Preferences',
        permissions: {
            read: [Role.USER],
            write: []
        }
    }
};
export default routes;
