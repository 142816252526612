var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { keyframes } from 'styled-components';
var spin = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n   0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n"], ["\n   0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n"], ["\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n"])));
var Loader = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-radius: 50%;\n  width: 10em;\n  height: 10em;\n  margin: 60px auto;\n  font-size: 10px;\n  position: relative;\n  text-indent: -9999em;\n  border-top: 1.1em solid var(--primary-color);\n  border-right: 1.1em solid var(--primary-color);\n  border-bottom: 1.1em solid var(--primary-color);\n  border-left: 1.1em solid #ffffff;\n  transform: translateZ(0);\n  animation: ", " 1s infinite linear;\n  ::after {\n    border-radius: 50%;\n    width: 10em;\n    height: 10em;\n  }\n"], ["\n  border-radius: 50%;\n  width: 10em;\n  height: 10em;\n  margin: 60px auto;\n  font-size: 10px;\n  position: relative;\n  text-indent: -9999em;\n  border-top: 1.1em solid var(--primary-color);\n  border-right: 1.1em solid var(--primary-color);\n  border-bottom: 1.1em solid var(--primary-color);\n  border-left: 1.1em solid #ffffff;\n  transform: translateZ(0);\n  animation: ", " 1s infinite linear;\n  ::after {\n    border-radius: 50%;\n    width: 10em;\n    height: 10em;\n  }\n"])), spin);
var PreLoader = function (props) { return _jsx(Container, { children: _jsx(Loader, {}) }); };
export default PreLoader;
var templateObject_1, templateObject_2, templateObject_3;
