var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import monthlyPreferencesApi from '@store/api/preferences.api';
import messages from './messages';
var initialState = {
    isLoading: false,
    error: {},
    monthlyPreferences: {
        data: {},
        error: {},
        loading: false
    },
    leaves: {
        data: {},
        error: {},
        loading: false
    },
    deadlines: {
        monthlyPreferencesDeadline: {
            data: {},
            error: {},
            loading: false
        },
        shiftPreferencesDeadline: {
            data: {},
            error: {},
            loading: false
        }
    },
    unavailableWeekend: {
        data: {},
        error: {},
        loading: false
    },
    leavePreferences: {
        data: {},
        error: {},
        loading: false,
        apiErrorMessage: {}
    },
    shiftPreferences: {
        data: {},
        error: {},
        loading: false,
        apiErrorMessage: {}
    }
};
var getShiftPreferencesError = function (code) {
    var errorMessage = '';
    if (code !== 200) {
        errorMessage = messages.preferenceUpdateFailed;
    }
    return errorMessage;
};
// Monthly Preferences
export var fetchMonthlyPreferences = createAsyncThunk('monthlyPreferences/fetchMonthlyPreferences', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.getMonthlyPreferences.initiate())];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var createMonthlyPreferences = createAsyncThunk('monthlyPreferences/createMonthlyPreferences', function (_a, thunkApi) {
    var dates = _a.dates, reason = _a.reason, type = _a.type;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.createMonthlyPreferences.initiate({ dates: dates, reason: reason, type: type }))];
                case 1:
                    void (_b.sent());
                    return [2 /*return*/];
            }
        });
    });
});
export var deleteMonthlyPreferences = createAsyncThunk('monthlyPreferences/deleteMonthlyPreferences', function (id, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.deleteMonthlyPreferences.initiate(id))];
            case 1:
                void (_a.sent());
                return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.getMonthlyPreferences.initiate())];
            case 2:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var fetchMonthlyPreferencesDeadline = createAsyncThunk('monthlyPreferences/fetchMonthlyPreferencesDeadline', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.getMonthlyPreferencesDeadline.initiate())];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
// Unavailable Weekened
export var fetchUnavailableWeekend = createAsyncThunk('monthlyPreferences/fetchUnavailableWeekend', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.getUnavailableWeekend.initiate())];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var deleteUnavailableWeekend = createAsyncThunk('monthlyPreferences/deleteUnavailableWeekend', function (id, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.deleteUnavailableWeekend.initiate(id))];
            case 1:
                void (_a.sent());
                return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.getUnavailableWeekend.initiate())];
            case 2:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
// Shift Preference Deadline
export var fetchShiftPreferencesDeadline = createAsyncThunk('monthlyPreferences/fetchShiftPreferencesDeadline', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.getShiftPreferencesDeadline.initiate())];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var fetchLeavePreferences = createAsyncThunk('leavePreferences/fetchLeavePreferences', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.getLeavePreferences.initiate())];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var createLeavePreferences = createAsyncThunk('monthlyPreferences/createLeavePreferences', function (_a, thunkApi) {
    var id = _a.id, employeesId = _a.employeesId, newLeaves = _a.newLeaves;
    return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.createLeavePreferences.initiate({ id: id, employees_id: employeesId, days: newLeaves }))];
                case 1:
                    void (_b.sent());
                    return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.getLeavePreferences.initiate())];
                case 2:
                    void (_b.sent());
                    return [2 /*return*/];
            }
        });
    });
});
// Shift Preferences
export var fetchShiftPreferences = createAsyncThunk('shiftPreferences/fetchShiftPreferences', function (_, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.getShiftPreferences.initiate())];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
export var createShiftPreferences = createAsyncThunk('shiftPreferences/createShiftPreferences', function (data, thunkApi) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, thunkApi.dispatch(monthlyPreferencesApi.endpoints.createShiftPreferences.initiate(data))];
            case 1:
                void (_a.sent());
                return [2 /*return*/];
        }
    });
}); });
var getError = function (code) {
    switch (code) {
        case 409000:
            return messages.duplicateEntry;
        case 400003:
            return messages.invalidDate;
        default:
            return messages.defaultError;
    }
};
export var monthlyPreferencesSlice = createSlice({
    name: 'monthly-preferences',
    initialState: initialState,
    reducers: {
        setMonthlyPreferences: function (state, action) {
            state.monthlyPreferences = action.payload.data;
        }
    },
    extraReducers: function (builder) {
        builder
            .addMatcher(monthlyPreferencesApi.endpoints.getMonthlyPreferences.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.monthlyPreferences.loading = true;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getMonthlyPreferences.matchRejected, function (state, _a) {
            var payload = _a.payload, type = _a.type;
            state.monthlyPreferences.loading = false;
            state.monthlyPreferences.error = payload;
            state.monthlyPreferences.data = {}; // If all the entries are deleted backend returns an error so controls comes to here. So making it empty to trigger rerender when last entry is deleted.
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getMonthlyPreferences.matchFulfilled, function (state, _a) {
            var payload = _a.payload, type = _a.type;
            state.monthlyPreferences.loading = false;
            state.monthlyPreferences.data = payload.result;
        });
        builder.addMatcher(monthlyPreferencesApi.endpoints.getUnavailableWeekend.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.unavailableWeekend.loading = true;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getUnavailableWeekend.matchRejected, function (state, _a) {
            var payload = _a.payload, type = _a.type;
            state.unavailableWeekend.loading = false;
            state.unavailableWeekend.error = payload;
            state.unavailableWeekend.data = {}; // If all the entries are deleted backend returns an error so controls comes to here. So making it empty to trigger rerender when last entry is deleted.
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getUnavailableWeekend.matchFulfilled, function (state, _a) {
            var payload = _a.payload, type = _a.type;
            state.unavailableWeekend.loading = false;
            state.unavailableWeekend.data = payload.result;
        });
        builder.addMatcher(monthlyPreferencesApi.endpoints.getMonthlyPreferencesDeadline.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.deadlines.monthlyPreferencesDeadline.loading = true;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getMonthlyPreferencesDeadline.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.deadlines.monthlyPreferencesDeadline.loading = false;
            state.deadlines.monthlyPreferencesDeadline.error = payload;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getMonthlyPreferencesDeadline.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.deadlines.monthlyPreferencesDeadline.loading = false;
            state.deadlines.monthlyPreferencesDeadline.data = payload.result;
        });
        builder.addMatcher(monthlyPreferencesApi.endpoints.getUnavailableWeekend.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.unavailableWeekend.loading = true;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getUnavailableWeekend.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.unavailableWeekend.loading = false;
            state.unavailableWeekend.error = payload;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getUnavailableWeekend.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.unavailableWeekend.loading = false;
            state.unavailableWeekend.data = payload.result;
        });
        builder.addMatcher(monthlyPreferencesApi.endpoints.getShiftPreferencesDeadline.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.deadlines.shiftPreferencesDeadline.loading = true;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getShiftPreferencesDeadline.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.deadlines.shiftPreferencesDeadline.loading = false;
            state.deadlines.shiftPreferencesDeadline.error = payload;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getShiftPreferencesDeadline.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.deadlines.shiftPreferencesDeadline.loading = false;
            state.deadlines.shiftPreferencesDeadline.data = payload.result;
        });
        builder.addMatcher(monthlyPreferencesApi.endpoints.getLeavePreferences.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.leavePreferences.loading = true;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getLeavePreferences.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.leavePreferences.loading = false;
            state.leavePreferences.error = payload;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getLeavePreferences.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.leavePreferences.loading = false;
            state.leavePreferences.data = payload.result;
        });
        builder.addMatcher(monthlyPreferencesApi.endpoints.createLeavePreferences.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.leavePreferences.loading = false;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.createLeavePreferences.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.leavePreferences.apiErrorMessage = __assign(__assign({}, payload === null || payload === void 0 ? void 0 : payload.data), { message: getError(payload === null || payload === void 0 ? void 0 : payload.status_code) });
        });
        builder.addMatcher(monthlyPreferencesApi.endpoints.getShiftPreferences.matchPending, function (state, _a) {
            var payload = _a.payload;
            state.shiftPreferences.loading = true;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getShiftPreferences.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.shiftPreferences.loading = false;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.getShiftPreferences.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.shiftPreferences.loading = false;
            state.shiftPreferences.data = payload.result;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.createMonthlyPreferences.matchPending, function (state) {
            state.isLoading = true;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.createMonthlyPreferences.matchRejected, function (state, _a) {
            var _b;
            var payload = _a.payload;
            state.isLoading = false;
            state.error = __assign(__assign({}, payload === null || payload === void 0 ? void 0 : payload.data), { message: getError((_b = payload === null || payload === void 0 ? void 0 : payload.data) === null || _b === void 0 ? void 0 : _b.status_code) });
        })
            .addMatcher(monthlyPreferencesApi.endpoints.createMonthlyPreferences.matchFulfilled, function (state) {
            state.isLoading = false;
            state.error = {};
        });
        builder.addMatcher(monthlyPreferencesApi.endpoints.createShiftPreferences.matchRejected, function (state, _a) {
            var payload = _a.payload;
            state.shiftPreferences.loading = false;
        })
            .addMatcher(monthlyPreferencesApi.endpoints.createShiftPreferences.matchFulfilled, function (state, _a) {
            var payload = _a.payload;
            state.shiftPreferences.apiErrorMessage = __assign(__assign({}, payload === null || payload === void 0 ? void 0 : payload.data), { message: getShiftPreferencesError(payload === null || payload === void 0 ? void 0 : payload.status_code) });
        });
    }
});
export var setMonthlyPreferences = monthlyPreferencesSlice.actions.setMonthlyPreferences;
export default monthlyPreferencesSlice.reducer;
