/**
 * Supported API Methods
 */
export var ApiMethods;
(function (ApiMethods) {
    ApiMethods["GET"] = "GET";
    ApiMethods["POST"] = "POST";
    ApiMethods["PUT"] = "PUT";
    ApiMethods["DELETE"] = "DELETE";
    ApiMethods["PATCH"] = "PATCH";
})(ApiMethods || (ApiMethods = {}));
