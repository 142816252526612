var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import media from '@utils/media';
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  position: relative;\n  ", "\n"], ["\n  display: flex;\n  align-items: center;\n  position: relative;\n  ", "\n"])), media.lessThan('sm')(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    align-items: end;\n    padding-bottom: 0.7rem;\n  "], ["\n    align-items: end;\n    padding-bottom: 0.7rem;\n  "]))));
var Tooltip = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  max-width: 34.3rem;\n  width: max-content;\n  background-color: #f8f8f8;\n  border-radius: 0.6rem;\n  padding: 1.5rem 1.1rem;\n  position: absolute;\n  z-index: 9;\n  top: 113%;\n  left: 50%;\n  transform: translate(-50%);\n  box-shadow: 0rem -0.2rem 1.5rem 0rem rgba(0,0,0,0.2);\n  /* font */\n  font: 1.4rem PoppinsRegular;\n  color: #25263b;\n  &::after {\n    content: \"\";\n    position: absolute;\n    bottom: 100%;\n    left: 50%;\n    margin-left: -1rem;\n    border-width: 1rem;\n    border-style: solid;\n    border-color: transparent transparent #f8f8f8 transparent;\n  }\n  ", "\n  ", "\n  ", "\n"], ["\n  display: flex;\n  flex-direction: column;\n  max-width: 34.3rem;\n  width: max-content;\n  background-color: #f8f8f8;\n  border-radius: 0.6rem;\n  padding: 1.5rem 1.1rem;\n  position: absolute;\n  z-index: 9;\n  top: 113%;\n  left: 50%;\n  transform: translate(-50%);\n  box-shadow: 0rem -0.2rem 1.5rem 0rem rgba(0,0,0,0.2);\n  /* font */\n  font: 1.4rem PoppinsRegular;\n  color: #25263b;\n  &::after {\n    content: \"\";\n    position: absolute;\n    bottom: 100%;\n    left: 50%;\n    margin-left: -1rem;\n    border-width: 1rem;\n    border-style: solid;\n    border-color: transparent transparent #f8f8f8 transparent;\n  }\n  ", "\n  ", "\n  ", "\n"])), media.lessThan('md')(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    max-width: 28rem;\n  "], ["\n    max-width: 28rem;\n  "]))), media.lessThan('sm')(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    max-width: 20rem;\n  "], ["\n    max-width: 20rem;\n  "]))), media.lessThan('ssm')(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    max-width: 15rem;\n  "], ["\n    max-width: 15rem;\n  "]))));
var CloseBtn = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: flex;\n  justify-content: right;\n  font-family: PoppinsMedium;\n  font-weight: 500;\n  color: #4e5cff;\n  cursor: pointer;\n"], ["\n  display: flex;\n  justify-content: right;\n  font-family: PoppinsMedium;\n  font-weight: 500;\n  color: #4e5cff;\n  cursor: pointer;\n"])));
var iconCss = css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
export default {
    Container: Container,
    Tooltip: Tooltip,
    CloseBtn: CloseBtn,
    iconCss: iconCss
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
