var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import styled from 'styled-components';
var StyledButton = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 50px;\n  max-height:  ", ";\n  padding: 13px 34px 14px;\n  border: none;\n  border-radius: 10px;\n  background-color: ", ";\n  color: ", ";\n  font: 1.6rem PoppinsMedium;\n  cursor: pointer;\n  :focus{\n    outline: none;\n  }\n  :active{\n    transform: translateY(1px);\n  }\n  :disabled{\n    color: #989899;\n    background-color: ", ";\n    cursor: not-allowed;\n  }\n  ", "\n"], ["\n  min-height: 50px;\n  max-height:  ", ";\n  padding: 13px 34px 14px;\n  border: none;\n  border-radius: 10px;\n  background-color: ", ";\n  color: ", ";\n  font: 1.6rem PoppinsMedium;\n  cursor: pointer;\n  :focus{\n    outline: none;\n  }\n  :active{\n    transform: translateY(1px);\n  }\n  :disabled{\n    color: #989899;\n    background-color: ", ";\n    cursor: not-allowed;\n  }\n  ", "\n"])), function (_a) {
    var height = _a.height;
    return (height !== null && height !== void 0 ? height : 'auto');
}, function (_a) {
    var transparentBg = _a.transparentBg;
    return (transparentBg === true ? 'transparent' : '#4e5cff');
}, function (_a) {
    var transparentBg = _a.transparentBg;
    return (transparentBg === true ? '#4e5cff' : '#ffffff');
}, function (_a) {
    var transparentBg = _a.transparentBg;
    return (transparentBg === true ? 'transparent' : '#e4e4e4');
}, function (_a) {
    var customStyles = _a.customStyles;
    return "".concat(customStyles);
});
var Button = function (props) {
    var customStyles = props.customStyles, text = props.text, transparentBg = props.transparentBg, disabled = props.disabled, onClick = props.onClick, type = props.type, className = props.className, height = props.height;
    return _jsx(_Fragment, { children: _jsx(StyledButton, __assign({ className: className, type: type, customStyles: customStyles, height: height, transparentBg: transparentBg, disabled: disabled, onClick: onClick }, { children: text })) });
};
export default Button;
var templateObject_1;
