var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/indent */
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import routes from '@constants/route.constants';
import RootLayout from '@containers/Layouts/RootLayout';
import ProtectedLayout from '@containers/Layouts/ProtectedLayout';
import RouteManager from './RouteManager';
import AppLoader from '@components/AppLoader';
;
var Loadable = function (Component) {
    var SuspenseHOC = function (props) { return (_jsx(Suspense, __assign({ fallback: _jsx(AppLoader, {}) }, { children: _jsx(Component, __assign({}, props)) }))); };
    return SuspenseHOC;
};
/**
 * Dynamically imported routes
 */
// const Login = Loadable(lazy(async () => await new Promise((resolve, reject) => { })));
var Login = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Login')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
/** Dashboard */
var Dashboard = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var Today = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/Today')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var TodayRoot = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/Today/TodayRoot')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var HowToLinks = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/Today/HowToLinks/HowToLinkDetailedView')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var ShiftDetailedView = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/Today/ShiftStatus/ShiftDetailedView')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
/* Monthly */
var Monthly = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/Monthly')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var MonthlyRoot = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/Monthly/MonthlyRoot')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var DayDetailedRoot = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/Monthly/DayDetailedView/DayDetailedRoot')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var DayShiftDetails = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/Monthly/DayDetailedView')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var GenerateShiftRoot = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/GenerateShift/GenerateShiftPreferencesRoot')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var GenerateShift = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/GenerateShift')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var GenerateShift1InitiateSurvey = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/GenerateShift/Step1-InitiateSurvey')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var GenerateShift2ReviewRequests = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/GenerateShift/Step2-ReviewRequests')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var GenerateShift3ReviewShiftPlanRoot = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/GenerateShift/Step3-ReviewShiftPlan/ReviewShiftPlanRoot')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var GenerateShift3ReviewShiftPlan = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/GenerateShift/Step3-ReviewShiftPlan')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var GenerateShift4EmployeeShifts = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/GenerateShift/Step4-EmployeeShifts')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var GenerateShift5AdminReports = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/GenerateShift/Step5-AdminReports')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var MemberUnavailableRoot = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/MemberUnavailable/MarkUnavailabilityRoot')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var MemberUnavailable = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/MemberUnavailable')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var MarkUnavailability = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/MemberUnavailable/MarkUnavailability')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var MemberUnavailableHistory = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/MemberUnavailable/MemberUnavailableHistory')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var Reports = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/Reports')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var EmployeeShiftSummary = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/EmployeeShiftSummary')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var MonthlyPreferences = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/MonthlyPreferences')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var MonthlyPreferencesRoot = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/MonthlyPreferences/MonthlyPreferencesRoot')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var Leaves = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/MonthlyPreferences/Leaves')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var UnavailableWeekend = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/MonthlyPreferences/UnavailableWeekend')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var AddNewLeave = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/MonthlyPreferences/AddNewLeave')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var FAQ = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/Dashboard/views/FAQ')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var ProjectDetailsRoot = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/ProjectDetails/ProjectDetailsRoot')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var ProjectDetails = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/ProjectDetails')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var ProjectDetailsEdit = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/ProjectDetails/EditProject')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var ShiftDetails = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/ShiftDetails')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var TeamMembers = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/TeamMembers')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
/* Shift Details */
var ShiftDetailsEdit = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/ShiftDetails/EditShift')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var ShiftDetailsView = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/ShiftDetails/ViewShift')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var GeneralConfig = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/ShiftDetails/GeneralConfig')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var ShiftDetailsDashboard = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/ShiftDetails/LandingPage')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
// GeneralConfig
/* My Profile */
var MyProfile = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/MyProfile')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var GeneralLeavePreferences = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/MyProfile/GeneralLeavePreferences')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
var GeneralShiftPreferences = Loadable(lazy(function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, import('@containers/MyProfile/GeneralShiftPreferences')];
        case 1: return [2 /*return*/, _a.sent()];
    }
}); }); }));
export var routeConfig = [
    __assign(__assign({}, (routes.ROOT)), { component: _jsx(RootLayout, {}), children: [
            __assign(__assign({}, (routes.HOME)), { component: _jsx(ProtectedLayout, {}), 
                // rootRedirect: routes.DASHBOARD.path,
                children: [
                    __assign(__assign({}, routes.DASHBOARD), { component: _jsx(Dashboard, {}), rootRedirect: routes.DASHBOARD_TODAY.path, children: [
                            __assign(__assign({}, routes.DASHBOARD_TODAY), { component: _jsx(TodayRoot, {}), children: [
                                    {
                                        component: _jsx(Today, {}),
                                        isRoot: true
                                    },
                                    __assign(__assign({}, routes.FAQ), { component: _jsx(FAQ, {}) }),
                                    __assign(__assign({}, routes.HOW_TO_LINK), { component: _jsx(HowToLinks, {}) }),
                                    __assign(__assign({}, routes.SHIFT_DETAILED), { component: _jsx(ShiftDetailedView, {}) })
                                ] }),
                            __assign(__assign({}, routes.DASHBOARD_MONTHLY), { component: _jsx(MonthlyRoot, {}), children: [
                                    {
                                        component: _jsx(Monthly, {}),
                                        isRoot: true
                                    },
                                    __assign(__assign({}, routes.DAY_SHIFT_DETAILS), { component: _jsx(DayDetailedRoot, {}), children: [
                                            {
                                                component: _jsx(DayShiftDetails, {}),
                                                isRoot: true
                                            },
                                            __assign(__assign({}, routes.MONTHLY_SHIFT_DETAILS), { component: _jsx(ShiftDetailedView, {}) })
                                        ] })
                                ] }),
                            __assign(__assign({}, routes.DASHBOARD_GENERATE_SHIFT), { component: _jsx(GenerateShiftRoot, {}), children: [
                                    {
                                        component: _jsx(GenerateShift, {}),
                                        isRoot: true,
                                        rootRedirect: routes.GENERATE_SHIFT_FLOW_STEP_1.path,
                                        children: [
                                            __assign(__assign({}, routes.GENERATE_SHIFT_FLOW_STEP_1), { component: _jsx(GenerateShift1InitiateSurvey, {}) }),
                                            __assign(__assign({}, routes.GENERATE_SHIFT_FLOW_STEP_2), { component: _jsx(GenerateShift2ReviewRequests, {}) }),
                                            __assign(__assign({}, routes.GENERATE_SHIFT_FLOW_STEP_3), { component: _jsx(GenerateShift3ReviewShiftPlanRoot, {}), children: [
                                                    {
                                                        component: _jsx(GenerateShift3ReviewShiftPlan, {}),
                                                        isRoot: true
                                                    },
                                                    __assign(__assign({}, routes.DAY_SHIFT_DETAILS), { component: _jsx(DayDetailedRoot, {}), children: [
                                                            {
                                                                component: _jsx(DayShiftDetails, {}),
                                                                isRoot: true
                                                            },
                                                            __assign(__assign({}, routes.MONTHLY_SHIFT_DETAILS), { component: _jsx(ShiftDetailedView, {}) })
                                                        ] })
                                                ] }),
                                            __assign(__assign({}, routes.GENERATE_SHIFT_FLOW_STEP_4), { component: _jsx(GenerateShift4EmployeeShifts, {}) }),
                                            __assign(__assign({}, routes.GENERATE_SHIFT_FLOW_STEP_5), { component: _jsx(GenerateShift5AdminReports, {}) })
                                        ]
                                    }
                                ] }),
                            __assign(__assign({}, routes.DASHBOARD_MARK_UNAVAILABILITY), { component: _jsx(MemberUnavailableRoot, {}), children: [
                                    {
                                        component: _jsx(MemberUnavailable, {}),
                                        isRoot: true,
                                        rootRedirect: routes.MEMBER_UNAVAILABLE_HISTORY.path,
                                        children: [
                                            __assign(__assign({}, routes.MEMBER_UNAVAILABLE_HISTORY), { component: _jsx(MemberUnavailableHistory, {}) })
                                        ]
                                    },
                                    __assign(__assign({}, routes.MARK_UNAVAILABILITY), { component: _jsx(MarkUnavailability, {}) })
                                ] }),
                            __assign(__assign({}, routes.DASHBOARD_MONTLY_PREFERENCES), { component: _jsx(MonthlyPreferencesRoot, {}), children: [
                                    {
                                        component: _jsx(MonthlyPreferences, {}),
                                        isRoot: true,
                                        rootRedirect: routes.LEAVES.path,
                                        children: [
                                            __assign(__assign({}, routes.LEAVES), { component: _jsx(Leaves, {}) }),
                                            __assign(__assign({}, routes.UNAVAILABLE_WEEKENDS), { component: _jsx(UnavailableWeekend, {}) })
                                        ]
                                    },
                                    __assign(__assign({}, routes.ADD_NEW_LEAVE), { component: _jsx(AddNewLeave, { type: routes.ADD_NEW_LEAVE.id }) }),
                                    __assign(__assign({}, routes.ADD_NEW_UNAVAILABLE_WEEKENDS), { component: _jsx(AddNewLeave, { type: routes.ADD_NEW_UNAVAILABLE_WEEKENDS.id }) })
                                ] }),
                            __assign(__assign({}, routes.DASHBOARD_REPORTS), { component: _jsx(Reports, {}) }),
                            __assign(__assign({}, routes.DASHBOARD_EMPLOYEE_SHIFT_SUMMARY), { component: _jsx(EmployeeShiftSummary, {}) })
                        ] }),
                    __assign(__assign({}, (routes.PROJECT_DETAILS)), { component: _jsx(ProjectDetailsRoot, {}), children: [
                            __assign(__assign({}, (routes.PROJECT_DETAILS)), { component: _jsx(ProjectDetails, {}), isRoot: true }),
                            __assign(__assign({}, (routes.PROJECT_DETAILS_ADD)), { component: _jsx(ProjectDetailsEdit, {}) })
                        ] }),
                    __assign(__assign({}, (routes.SHIFT_DETAILS)), { component: _jsx(ShiftDetails, {}), rootRedirect: routes.SHIFT_DETAILS_LANDING.path, children: [
                            __assign(__assign({}, routes.SHIFT_DETAILS_LANDING), { component: _jsx(ShiftDetailsDashboard, {}), isRoot: true }),
                            __assign(__assign({}, routes.SHIFT_DETAILS_CONFIG), { component: _jsx(GeneralConfig, {}) }),
                            __assign(__assign({}, (routes.SHIFT_DETAILS_ADD)), { component: _jsx(ShiftDetailsEdit, {}) }),
                            __assign(__assign({}, (routes.SHIFT_DETAILS_VIEW)), { component: _jsx(ShiftDetailsView, {}) })
                        ] }),
                    __assign(__assign({}, (routes.TEAM_MEMBERS)), { component: _jsx(TeamMembers, {}) }),
                    __assign(__assign({}, routes.MY_PROFILE), { component: _jsx(MyProfile, {}) }),
                    __assign(__assign({}, routes.GENERAL_LEAVE_PREFERENCES), { component: _jsx(GeneralLeavePreferences, {}) }),
                    __assign(__assign({}, routes.GENERAL_SHIFT_PREFERENCES), { component: _jsx(GeneralShiftPreferences, {}) })
                ] }),
            __assign(__assign({}, routes.LOGIN), { component: _jsx(Login, {}) })
        ] })
];
var getRouterMap = function (routeConfig, rootRedirect) {
    if (rootRedirect) {
        routeConfig.unshift({
            component: _jsx(Navigate, { replace: true, to: rootRedirect }),
            isRoot: true
        });
    }
    ;
    return routeConfig.map(function (route) { return (__assign(__assign({ path: route.path, element: _jsx(RouteManager, { children: route.component }) }, ((route.children !== undefined)
        ? {
            children: getRouterMap(route.children.map(function (el) { return (__assign(__assign({}, el), { permissions: el.permissions ? el.permissions : route.permissions })); }), route.rootRedirect)
        }
        : { index: Boolean(route.isRoot) })), { handle: { meta: { id: route.id, title: route.title }, permissions: route.permissions } })); });
};
var router = createBrowserRouter(getRouterMap(routeConfig));
var AppRoutes = function () { return _jsx(RouterProvider, { router: router }); };
export default AppRoutes;
