import { ApiMethods } from '@enums/api.enum';
import { api } from './common.api';
import { SHIFT_DETAILS } from '@constants/api.constants';
var shiftDetailsApi = api.injectEndpoints({
    endpoints: function (builder) { return ({
        createShift: builder.mutation({
            query: function (body) { return ({
                url: SHIFT_DETAILS.shifts,
                method: ApiMethods.POST,
                body: body
            }); }
        }),
        editShift: builder.mutation({
            query: function (body) { return ({
                url: SHIFT_DETAILS.shifts,
                method: ApiMethods.PUT,
                body: body
            }); }
        }),
        getShiftDetails: builder.query({
            query: function () { return ({
                url: SHIFT_DETAILS.shifts,
                method: ApiMethods.GET
            }); }
        }),
        deleteShift: builder.query({
            query: function (id) { return ({
                url: "".concat(SHIFT_DETAILS.shifts, "?id=").concat(id),
                method: ApiMethods.DELETE
            }); }
        }),
        disableShift: builder.query({
            query: function (body) { return ({
                url: SHIFT_DETAILS.shifts,
                method: ApiMethods.PATCH,
                body: body
            }); }
        })
    }); }
});
export default shiftDetailsApi;
